import {Box, InputLabel, TextField} from '@mui/material'
import React, {useState} from 'react'
import {ContentAssist} from "../../../../../../interfaces/ContentAssistType";
import RichTextAreaEditor from "../../../../../../components/RichTextAreaEditor";
import AutocompleteExt from "../../../../../../components/Autocomplete";
import Image from "../../../../../../components/Image";

const PublicApiContentAssistDetail = (props: { contentWrapper: ContentAssist }) => {
    const [content] = useState<ContentAssist>(props.contentWrapper)

    return (
        <Box>
            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(1, minmax(0,1fr))"
            >
                <TextField
                    variant="filled"
                    type="text"
                    label="Id"
                    value={content.id}
                    name="id"
                />

                <TextField
                    variant="filled"
                    type="number"
                    label="Week"
                    value={content.week}
                    name="week"
                />

                <TextField
                    variant="filled"
                    type="number"
                    label="Day"
                    value={content.day}
                    name="day"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Title"
                    value={content.title}
                    name="title"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="SubTitle"
                    value={content.subTitle}
                    name="title"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Reference Url"
                    value={content.referenceUrl}
                    name="referenceUrl"
                />

                <RichTextAreaEditor
                    label="Body"
                    value={content.body}
                    editable={false}
                />

                <AutocompleteExt
                    name="contentTypes"
                    multiSelection={true}
                    label="Content Types"
                    selectedValue={content.contentTypes.map((type) => type.id)}
                    options={content.contentTypes.map((type) => {
                        return {
                            label: type.type,
                            value: type.id,
                        }
                    })}
                    disableUnselectAll={true}
                />

                <Image label="Media" url={content.media?.url} />

                <Image label="Banner" url={content.banner?.url} />

                <TextField
                    variant="filled"
                    type="text"
                    label="Locale"
                    value={content.locale}
                    name="locale"
                />

            </Box>
        </Box>
    )
}

export default PublicApiContentAssistDetail
