/**
 * An action to fetch contents through public api
 *
 * No pagination will be handled at this stage
 */
import Axios from '../extensions/Axios'

export const fetchContents = async (tenantCredential, searchOptions) => {
    let contentUrl = `${tenantCredential.apiUrl}/content/assist?page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`

    if (searchOptions.customSearchOptions.week) {
        contentUrl += `&week=${searchOptions.customSearchOptions.week}`
    }
    if (searchOptions.customSearchOptions?.locale) {
        contentUrl += `&locale=${searchOptions.customSearchOptions.locale}`
    }

    if (searchOptions.customSearchOptions?.tagIds) {
        contentUrl += `&tagIds=${searchOptions.customSearchOptions?.tagIds.join(',')}`
        if (searchOptions.customSearchOptions?.weekDuration) {
            contentUrl += `&weekDuration=${searchOptions.customSearchOptions.weekDuration}`
        }
    }

    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        contentUrl += `&sortBy=${
            searchOptions.sortOptions.sortField
        }&sortByDesc=${searchOptions.sortOptions.sortOrder === 'desc'}`
    }
    const { data } = await Axios.get(contentUrl, {
        headers: {
            'X-Tenant-Id': tenantCredential.tenantId,
            'X-Api-Key': tenantCredential.tenantApiKey,
        },
    })
    return data
}

/**
 * An action to fetch all my settings
 * @param tenantCredential
 * @returns {Promise<void>}
 */
export async function fetchAllSettings(tenantCredential) {
    const localeSettings = await fetchLocaleSettings(tenantCredential)
    const tagSettings = await fetchTagSettings(tenantCredential)

    return {
        locales: localeSettings,
        tags: tagSettings
    }
}

/**
 * An action to fetch my locales settings
 */
export async function fetchLocaleSettings(tenantCredential) {
    let getLocaleSettings = `${tenantCredential.apiUrl}/setting/locale`

    const { data } = await Axios.get(getLocaleSettings, {
        headers: {
            'X-Tenant-Id': tenantCredential.tenantId,
            'X-Api-Key': tenantCredential.tenantApiKey,
        },
    })
    return data.data
}

/**
 * An action to fetch my tag settings
 */
export async function fetchTagSettings(tenantCredential) {
    let getTagSettings = `${tenantCredential.apiUrl}/setting/content/tag`

    const { data } = await Axios.get(getTagSettings, {
        headers: {
            'X-Tenant-Id': tenantCredential.tenantId,
            'X-Api-Key': tenantCredential.tenantApiKey,
        },
    })
    return data.data
}