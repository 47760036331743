import {
    getValueByKey,
    updateValueByKey,
} from '../../extensions/EncryptStorage'
import { LOAD_GENERAL_SETTING, UPDATE_GENERAL_SETTING } from '../actionType'

/**
 * Default state declaration
 */
const initialState = {
    setting: {
        range: {
            weekFrom: 1,
            weekTo: 36,
        },
    },
}

/**
 * Update general setting redux state through reducer
 *
 * @param {*} state - immutable state for page/ component update when there is changes depending on action
 * @param {*} action - an action contains type of changes, and payload for update
 * @returns
 */
export default function generalSetting(state = initialState, action) {
    switch (action.type) {
        case LOAD_GENERAL_SETTING: {
            let generalSetting = getValueByKey(LOAD_GENERAL_SETTING)
            if (!generalSetting) {
                updateValueByKey(LOAD_GENERAL_SETTING, state.setting)
                generalSetting = state.setting
            }

            return Object.assign({}, state, {
                setting: generalSetting,
            })
        }
        case UPDATE_GENERAL_SETTING: {
            updateValueByKey(LOAD_GENERAL_SETTING, action.payload)
            return Object.assign({}, state, {
                setting: action.payload,
            })
        }
        default:
            return state
    }
}
