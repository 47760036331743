import React, { useState } from 'react'
import { Box } from '@mui/material'
import {
    MobileUserApprovalSetting,
    MobileUserApprovalSettingCustomFilterOptions
} from "../../../../../interfaces/SettingType";
import DataGridFilter, {SearchOptionsProp} from "../../../../../components/DataGridFilter";
import Header from "../../../../../components/Header";
import DatePickerExt from "../../../../../components/DatePickerExt";
import MobileUserApprovalSettingsDetail from "./detail";
import {fetchMobileUserApprovalSettings} from "../../../../../actions/user";
import {AccessControlGroup} from "../../../../../interfaces/AccessControlType";
import {useAuthQueryWithQueryFunction} from "../../../../../extensions/UseAuthQuery";
import {ApiError} from "../../../../../interfaces/ErrorType";
import {fetchAllAccessControlGroups} from "../../../../../actions/security";
import AutocompleteExt from "../../../../../components/Autocomplete";

function MobileUserApprovalSettings() {

    const [customSearchOptions, setCustomSearchOptions] =
        useState<MobileUserApprovalSettingCustomFilterOptions>({
            dateFrom: undefined,
            dateTo: undefined,
            filteredByGroupId: undefined,
        })
    const [accessControlGroupOptions, setAccessControlGroupOptions] = useState<any[]>([])

    /**
     * Fetch all access control groups query
     */
    const fetchAllAccessControlGroupsQuery = useAuthQueryWithQueryFunction<AccessControlGroup[], ApiError, AccessControlGroup[]>(
        'access-control-groups',
        fetchAllAccessControlGroups,
        {
            onSuccess: (data) => {
                setAccessControlGroupOptions(data.map((group) => ({
                    value: group.id,
                    label: group.name,
                })))
            },
            refetchOnWindowFocus: false,
            enabled: true,
        }
    )

    const expandRow = (row: MobileUserApprovalSetting) => (
        <MobileUserApprovalSettingsDetail wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchMobileUserApprovalSettings(searchOptions)
    }

    const customSearchOptionsRenderer = () => (
        <>
            <DatePickerExt
                value={customSearchOptions.dateFrom}
                onChange={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        dateFrom: value,
                    })
                }}
                max={customSearchOptions.dateTo ? customSearchOptions.dateTo : new Date().toISOString().slice(0, 10)}
            />

            <DatePickerExt
                value={customSearchOptions.dateTo}
                onChange={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        dateTo: value,
                    })
                }}
                min={customSearchOptions.dateFrom}
            />

            <AutocompleteExt
                name="accessControlGroupId"
                multiSelection={false}
                label="Filter by Group..."
                selectedValue={customSearchOptions.filteredByGroupId}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        filteredByGroupId: value,
                    })
                }}
                options={accessControlGroupOptions}
                disableUnselectAll={false}
            />
        </>
    )

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            converter: (value: string) => {
                return value !== null ? value : '-'
            }
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'accessControlGroup',
            text: 'Group',
            sort: false,
            converter: (group: AccessControlGroup) => {
                if (group) {
                    return group.name
                }
                return '-'
            },
        },
        {
            dataField: 'whitelisted',
            text: 'Whitelisted',
            sort: false,
            converter: (value: boolean) => {
                if (value === null) {
                    return '-'
                }

                return value ? 'Yes' : 'No'
            }
        },
        {
            dataField: 'blacklisted',
            text: 'Blacklisted',
            sort: false,
            converter: (value: boolean) => {
                if (value === null) {
                    return '-'
                }

                return value ? 'Yes' : 'No'
            }
        },
        {
            dataField: 'createdAt',
            text: 'Sign Up Date',
            sort: true,
            converter: (value: string) => {
                if (value !== null) {
                    const toDate = new Date(value);
                    const formattedDate = `${toDate.getFullYear()}-${(toDate.getMonth() + 1).toString().padStart(2, '0')}-${toDate.getDate().toString().padStart(2, '0')} ${toDate.getHours().toString().padStart(2, '0')}:${toDate.getMinutes().toString().padStart(2, '0')}:${toDate.getSeconds().toString().padStart(2, '0')}`;
                    return formattedDate;
                }

                return '-'
            },
        }
    ]

    return (
        <Box m='20px'>
            <Header title='Mobile User Approval Settings' />

            <DataGridFilter
                keyField='id'
                useQueryKey={`mobile-user-approval-settings`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                customSearchOptions={customSearchOptions}
                resetCustomSearchOptions={setCustomSearchOptions}
                customSearchOptionsRenderer={customSearchOptionsRenderer()}
                searchFilterCols={3}
                disabledMatchAll={true}
                expandRow={expandRow}
            />

        </Box>
    )
}

export default MobileUserApprovalSettings