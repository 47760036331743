import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Box, InputLabel } from '@mui/material'

/**
 * Extended React component to render a specific image html tag, event function binding, and any
 * reusable attributes
 */
const Image: FunctionComponent<ImageProp> = ({
    label,
    url,
    alt,
    width = '200px',
    height = '200px',
}) => {
    let uuid = useMemo(() => uuidv4(), [])

    return (
        <Box style={{ marginLeft: `1em` }}>
            {label && (
                <InputLabel
                    style={{ fontSize: `x-small`, paddingBottom: `10px` }}
                >
                    {label}
                </InputLabel>
            )}
            <img
                key={uuid}
                src={url ? url : '/Unavailable.png'}
                alt={alt}
                width={width}
                height={height}
            />
        </Box>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface ImageProp {
    label?: string
    url?: string
    alt?: string
    width?: string
    height?: string
}

export default Image
