import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../components/DataGridFilter'
import {
    fetchAllContentTypeTfs,
    fetchContents,
    generateContentReport,
} from '../../../actions/contentAssists'
import { useAuthQueryWithQueryFunction } from '../../../extensions/UseAuthQuery'
import { fetchMyTags } from '../../../actions/user'
import { Box, TextField } from '@mui/material'
import {childStageOptions, daysOptions, translateToPreferLocaleOptions} from '../../../share/ContentAssistConstant'
import Header from '../../../components/Header'
import SelectExt from '../../../components/Select'
import ContentAssistDetail from './detail'
import AutocompleteExt from '../../../components/Autocomplete'
import {ContentAssist, ContentAssistFilterCustomFilterOptions} from '../../../interfaces/ContentAssistType'
import { stateOptions } from '../../../share/DefaultConstant'
import { ApiError } from '../../../interfaces/ErrorType'
import { v4 as uuidv4 } from 'uuid'

/**
 * Render Content Assist page
 *
 * @returns contents
 */
function ContentAssists(props: { localeList: any }) {
    const [change, setChange] = useState<string>()
    const localeList = props.localeList
    const [availableTypeOptions, setAvailableTypeOptions] = useState<any[]>([])
    const [customSearchOptions, setCustomSearchOptions] =
        useState<ContentAssistFilterCustomFilterOptions>({
            state: [],
            stage: childStageOptions[0].value,
            week: NaN,
            month: NaN,
            filteredBySelectedDays: [],
            filteredByTagIds: [],
            filteredByUpcomingWeekDuration: NaN,
            filteredByContentTypeIds: [],
            locale: 'en',
            translateToPreferLocale: undefined,
        })

    const localeOptions = localeList.map((locale: any) => {
        return {
            label: locale.name,
            value: locale.code,
        }
    })

    const expandRow = (row: ContentAssist) => (
        <ContentAssistDetail key={row.id} isNew={false} wrapper={row}  callback={setChange} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchContents(searchOptions)
    }

    const onDownloadEvent = (searchOptions: SearchOptionsProp) => {
        return generateContentReport(searchOptions)
    }

    /**
     * Fetch tag list
     * */
    const contentTagQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
    >('contentTags', fetchMyTags, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch types list
     * */
    const contentTypeQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >(['fetchContentTypeTfs'], fetchAllContentTypeTfs, {
        onSuccess: (data) => {
            setAvailableTypeOptions(data?.map((contentType) => {
                return {
                    value: contentType.id,
                    label: contentType.type
                }
            }))
        },
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const availableTagOptions = contentTagQuery.data?.map((contentTag) => {
        return {
            value: contentTag.id,
            label: contentTag.name,
        }
    })

    const customSearchOptionsRenderer = () => (
        <>
            <SelectExt
                name="filteredByStatus"
                multiSelection={true}
                label="Filtered by status..."
                selectedValue={customSearchOptions.state}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        state: value,
                    })
                }}
                options={stateOptions}
            />

            <SelectExt
                name="stage"
                multiSelection={false}
                label="Stage"
                selectedValue={customSearchOptions.stage}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        stage: value,
                        filteredByContentTypeIds: [],
                    })

                    setChange(uuidv4())
                }}
                options={childStageOptions}
                isClearable={false}
            />

            {customSearchOptions.stage === 'PREGNANCY' && (
                <TextField
                    type="number"
                    variant="filled"
                    name="filteredByWeek"
                    value={customSearchOptions.week}
                    label="Filtered by week..."
                    inputProps={{
                        min: 1,
                        max: 40,
                    }}
                    onChange={(event) => {
                        if (!event.target.value) {
                            setCustomSearchOptions({
                                ...customSearchOptions,
                                week: NaN,
                                month: NaN,
                                filteredBySelectedDays: [],
                                filteredByTagIds: [],
                                filteredByUpcomingWeekDuration: NaN,
                                translateToPreferLocale: undefined,
                            })
                        } else {
                            setCustomSearchOptions({
                                ...customSearchOptions,
                                week: parseInt(event.target.value),
                                month: NaN,
                            })
                        }
                    }}
                />
            )}

            {customSearchOptions.stage === 'INFANCY' && (
                <TextField
                    type="number"
                    variant="filled"
                    name="filteredByMonth"
                    value={customSearchOptions.month}
                    label="Filtered by month..."
                    inputProps={{
                        min: 1,
                        max: 12,
                    }}
                    onChange={(event) => {
                        if (!event.target.value) {
                            setCustomSearchOptions({
                                ...customSearchOptions,
                                month: NaN,
                                week: NaN,
                                filteredBySelectedDays: [],
                                filteredByTagIds: [],
                                filteredByUpcomingWeekDuration: NaN,
                                translateToPreferLocale: undefined,
                            })

                            return
                        }

                        setCustomSearchOptions({
                            ...customSearchOptions,
                            month: parseInt(event.target.value),
                            week: NaN,
                        })
                    }}
                />
            )}

            <AutocompleteExt
                name="contentTypes"
                multiSelection={true}
                label="Filtered by type..."
                selectedValue={customSearchOptions.filteredByContentTypeIds}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        filteredByContentTypeIds: value
                    })
                }}
                options={availableTypeOptions}
            />

            <AutocompleteExt
                name="filteredBySelectedDays"
                label="Filtered by selected days..."
                multiSelection={true}
                selectedValue={customSearchOptions.filteredBySelectedDays}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        filteredBySelectedDays: value,
                    })
                }}
                options={daysOptions}
                editable={
                    ((customSearchOptions.week !== undefined && customSearchOptions.week > 0) || (customSearchOptions.month !== undefined && customSearchOptions.month > 0)) &&
                    customSearchOptions.filteredByTagIds.length === 0
                }
            />

            <AutocompleteExt
                name="locale"
                multiSelection={false}
                label="Filtered by locale..."
                selectedValue={customSearchOptions.locale}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        locale: value,
                    })
                }}
                options={localeOptions}
            />

            <AutocompleteExt
                name="contentTags"
                multiSelection={true}
                label="Filtered by tag..."
                selectedValue={customSearchOptions.filteredByTagIds}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        filteredByTagIds: value,
                        filteredBySelectedDays: [],
                    })
                }}
                options={availableTagOptions}
                editable={(customSearchOptions.week !== undefined && customSearchOptions.week > 0) || (customSearchOptions.month !== undefined && customSearchOptions.month > 0)}
            />

            <TextField
                type="number"
                variant="filled"
                name="filteredByUpcomingWeekDuration"
                value={customSearchOptions.filteredByUpcomingWeekDuration}
                label="Filtered by week duration..."
                InputProps={{
                    inputProps: {
                        min: 1,
                    },
                }}
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        filteredByUpcomingWeekDuration: parseInt(
                            event.target.value
                        ),
                    })
                }}
                disabled={customSearchOptions.filteredByTagIds.length === 0}
            />

            <AutocompleteExt
                name="translateToPreferLocale"
                multiSelection={false}
                label="Translate to prefer locale..."
                selectedValue={customSearchOptions.translateToPreferLocale}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        translateToPreferLocale: value
                    })
                }}
                options={translateToPreferLocaleOptions}
                editable={(customSearchOptions.week !== undefined && customSearchOptions.week > 0) || (customSearchOptions.month !== undefined && customSearchOptions.month > 0)}
            />
        </>
    )

    const generateColumns = (stage: string) => {
        return stage === 'PREGNANCY' ? [
            {
                dataField: 'title',
                text: 'Title',
                sort: true,
            },
            {
                dataField: 'contentTypes',
                text: 'Content Type',
                conversion: 'type',
                sort: false,
            },
            {
                dataField: 'week',
                text: 'Pregnancy Week',
                sort: true,
            },
            {
                dataField: 'day',
                text: 'Day of The Week',
            },
            {
                dataField: 'priorityOrder',
                text: 'Priority',
                converter: (value: any) => {
                    if (value === 1) {
                        return "Main"
                    }
                    return "Random"
                },
            },
            {
                dataField: 'state',
                text: 'State',
            },
        ] : [
            {
                dataField: 'title',
                text: 'Title',
                sort: true,
            },
            {
                dataField: 'contentTypes',
                text: 'Content Type',
                conversion: 'type',
                sort: false,
            },
            {
                dataField: 'week',
                text: 'Infancy Month',
                sort: true,
                converter: (week: any) => {
                    const baseWeek = 40;
                    const weekOffset = week - baseWeek;

                    // Assuming a month is approximately 4.34524 weeks
                    let month = Math.ceil(weekOffset / 4.34524);

                    // Handle special case for values close to the boundary
                    const threshold = 0.5;
                    if (month > 1 && weekOffset % 4.34524 <= threshold) {
                        month--;
                    }

                    return month;
                }
            },
            {
                dataField: 'week',
                text: 'Infancy Week',
                sort: true,
            },
            {
                dataField: 'day',
                text: 'Infancy Day',
            },
            {
                dataField: 'priorityOrder',
                text: 'Priority',
                converter: (value: any) => {
                    if (value === 1) {
                        return "Main"
                    }
                    return "Random"
                },
            },
            {
                dataField: 'state',
                text: 'State',
            },
        ]
    }

    const columns = generateColumns(customSearchOptions.stage)

    /**
     * Page containing content
     */
    return (
        <Box m="20px">
            <Header title="Content Assist" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`contentAssists`}
                change={change}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                reportTitle={'Content-Report'}
                onDownloadEvent={onDownloadEvent}
                searchFilterCols={3}
                customSearchOptions={customSearchOptions}
                resetCustomSearchOptions={setCustomSearchOptions}
                customSearchOptionsRenderer={customSearchOptionsRenderer()}
                disabledMatchAll={true}
                expandRow={expandRow}
                maxItemsPerPage={100000}
            />
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { localeList: state.localeList.data }
}

export default connect(mapStateToProps)(ContentAssists)
