import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { Box, IconButton, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {AccessControlGroup, AccessControlGroupWrapper} from "../../../../../interfaces/AccessControlType";
import {ApiError} from "../../../../../interfaces/ErrorType";
import {saveAccessControlGroup} from "../../../../../actions/security";
import Header from "../../../../../components/Header";
import ErrorMessage from "../../../../../components/ErrorMessage";
import ButtonExt from "../../../../../components/ButtonExt";

const accessControlGroupSchema = yup.object().shape({
    name: yup.string().required('required'),
})

const SecurityAccessControlGroupDetail: FunctionComponent<AccessControlGroupWrapper> = ({
                                                                                  isNew = false,
                                                                                  wrapper,
                                                                              }) => {
    const navigate = useNavigate()
    const [accessControlGroup, setAccessControlGroup] = useState<AccessControlGroup>(
        wrapper
            ? wrapper
            : {
                id: undefined,
                name: '',
            }
    )

    /**
     * Mutate access control group create
     */
    const accessControlGroupCreateMutation = useMutation<AccessControlGroup, ApiError, AccessControlGroup>(
        saveAccessControlGroup,
        {
            onSuccess: (data) => {
                setAccessControlGroup(data)
            },
        }
    )

    /**
     * Mutate access control group update
     */
    const accessControlGroupUpdateMutation = useMutation<AccessControlGroup, ApiError, AccessControlGroup>(
        saveAccessControlGroup,
        {
            onSuccess: (data) => {
                setAccessControlGroup(data)
            },
        }
    )

    if (accessControlGroupCreateMutation.isSuccess) {
        navigate(`/security/access/group`)
    }

    /**
     * Invoke an action to create/ update access control group
     * @param {*} e - event
     */
    const onSave = (values: AccessControlGroup) => {
        if (!values.id) {
            accessControlGroupCreateMutation.mutate(values)
        } else {
            accessControlGroupUpdateMutation.mutate(values)
        }
    }

    /**
     * Page containing access control group details
     */
    return (
        <Box m="20px">
            {isNew && (
                <>
                    <Box
                        display="flex"
                        justifyContent="start"
                        mt="20px"
                        style={{ padding: `10px` }}
                    >
                        <IconButton
                            color="secondary"
                            onClick={() => navigate(`/security/access/group`)}
                        >
                            <ArrowBackIcon /> Back
                        </IconButton>
                    </Box>

                    <Header title="Create Access Control Group" />
                </>
            )}

            <Box style={{ marginBottom: `2em` }}>
                {accessControlGroupCreateMutation.isError && (
                    <ErrorMessage error={accessControlGroupCreateMutation.error} />
                )}

                {accessControlGroupUpdateMutation.isError && (
                    <ErrorMessage error={accessControlGroupUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={accessControlGroup}
                validationSchema={accessControlGroupSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            {accessControlGroup.id && (
                                <TextField
                                    variant="filled"
                                    type="text"
                                    label="Id"
                                    value={values.id}
                                    name="id"
                                />
                            )}

                            <TextField
                                variant="filled"
                                type="text"
                                label="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    accessControlGroupCreateMutation.isLoading ||
                                    accessControlGroupUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    accessControlGroupCreateMutation.isLoading ||
                                    accessControlGroupUpdateMutation.isLoading
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default SecurityAccessControlGroupDetail