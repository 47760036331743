import SecureAxios from "../extensions/SecureAxios";

/**
 * Search for qna (experimental)
 */
export async function searchQna(questionAnswerWrapper) {
    const requestBody = {
        key: questionAnswerWrapper.key,
        question: questionAnswerWrapper.question,
    }
    const { data } = await SecureAxios.post(
        `/consumer/neurofrog/crawler/qna/experiment`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return data.data
}

/**
 * Init external qna (experimental)
 */
export async function initExternalQna(childId) {
    const {data} = await SecureAxios.post(
        `/consumer/child/${childId}/external/chat`,
        {},
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return data.data
}

/**
 * Search for external qna (experimental)
 */
export async function searchExternalQna(externalQnaWrapper) {
    const requestBody = {
        question: externalQnaWrapper.question,
    }
    const {data} = await SecureAxios.post(
        `/consumer/child/${externalQnaWrapper.childId}/external/chat/${externalQnaWrapper.chatId}/text`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return data.data
}