import React, {useState} from 'react'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../components/DataGridFilter'
import Header from '../../../components/Header'
import {Alert, AlertTitle, Box} from '@mui/material'
import SecurityAccessControlDetail from "./detail";
import {
    AccessControl,
    AccessControlFilterCustomFilterOptions,
    AccessControlGroup
} from "../../../interfaces/AccessControlType";
import {
    blacklistAccessControlByGroupId,
    fetchAccessControl,
    fetchAllAccessControlGroups, whitelistAccessControlByGroupId
} from "../../../actions/security";
import {useAuthQueryWithQueryFunction} from "../../../extensions/UseAuthQuery";
import {ApiError} from "../../../interfaces/ErrorType";
import AutocompleteExt from "../../../components/Autocomplete";
import ButtonExt from "../../../components/ButtonExt";
import {useMutation} from "react-query";
import {v4} from "uuid";

/**
 * Security Access Control list
 *
 * @returns security access control list page
 */
function SecurityAccessControl() {
    const [blacklistMessage, setBlacklistMessage] = useState<string>()
    const [whitelistMessage, setWhitelistMessage] = useState<string>()
    const [change, setChange] = useState<string>()
    const [customSearchOptions, setCustomSearchOptions] =
        useState<AccessControlFilterCustomFilterOptions>({
            filteredByGroupId: undefined,
        })
    const [accessControlGroupOptions, setAccessControlGroupOptions] = useState<any[]>([])

    const expandRow = (row: AccessControl) => (
        <SecurityAccessControlDetail key={row.id} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchAccessControl(searchOptions)
    }

    /**
     * Fetch all access control groups query
     */
    const fetchAllAccessControlGroupsQuery = useAuthQueryWithQueryFunction<AccessControlGroup[], ApiError, AccessControlGroup[]>(
        'access-control-groups',
        fetchAllAccessControlGroups,
        {
            onSuccess: (data) => {
                setAccessControlGroupOptions(data.map((group) => ({
                    value: group.id,
                    label: group.name,
                })))
            },
            refetchOnWindowFocus: false,
            enabled: true,
        }
    )

    const blacklistAccessByGroupIdMutation = useMutation<any, ApiError, any>(
        blacklistAccessControlByGroupId,
    )

    const whitelistAccessByGroupIdMutation = useMutation<any, ApiError, any>(
        whitelistAccessControlByGroupId,
    )

    const onBlacklistAccessByGroupId = (groupId: string) => {
        blacklistAccessByGroupIdMutation.mutate(groupId, {
            onSuccess: () => {
                setWhitelistMessage('')
                setBlacklistMessage('Blacklisted')
                setTimeout(() => {
                    setBlacklistMessage(undefined)
                }, 5000); // 5 seconds
                setChange(v4())
            }
        })
    }

    const onWhitelistAccessByGroupId = (groupId: string) => {
        whitelistAccessByGroupIdMutation.mutate(groupId, {
            onSuccess: () => {
                setBlacklistMessage('')
                setWhitelistMessage('Whitelisted')
                setTimeout(() => {
                    setWhitelistMessage(undefined)
                }, 5000); // 5 seconds
                setChange(v4())
            }
        })
    }

    const customSearchOptionsRenderer = () => (
        <>
            <Box display="grid" gridTemplateColumns="3fr 1fr">
                <AutocompleteExt
                    name="accessControlGroupId"
                    multiSelection={false}
                    label="Filter by Group..."
                    selectedValue={customSearchOptions.filteredByGroupId}
                    onSelect={(value) => {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            filteredByGroupId: value,
                        })
                    }}
                    options={accessControlGroupOptions}
                    disableUnselectAll={false}
                />
                {customSearchOptions.filteredByGroupId && (
                    <Box display="flex" flexDirection="column">
                        <ButtonExt
                            type="button"
                            value={blacklistAccessByGroupIdMutation.isLoading ? 'Blacklisting...' : 'Blacklist'}
                            style={{marginTop: '20px'}}
                            onClickEvent={() => {
                                onBlacklistAccessByGroupId(customSearchOptions.filteredByGroupId!!)
                            }}
                        />
                        {blacklistMessage && (
                            <Alert severity="success">
                                {blacklistMessage}
                            </Alert>
                        )}
                        <ButtonExt
                            type="button"
                            value={whitelistAccessByGroupIdMutation.isLoading ? 'Whitelisting...' : 'Whitelist'}
                            style={{marginTop: '20px'}}
                            onClickEvent={() => {
                                onWhitelistAccessByGroupId(customSearchOptions.filteredByGroupId!!)
                            }}
                        />
                        {whitelistMessage && (
                            <Alert severity="success">
                                {whitelistMessage}
                            </Alert>
                        )}
                    </Box>
                )}
            </Box>
        </>
    );

    const columns = [
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'accessControlGroup',
            text: 'Group',
            sort: false,
            converter: (group: AccessControlGroup) => {
                if (group) {
                    return group.name
                }

                return '-'
            }
        },
        {
            dataField: 'whitelisted',
            text: 'Whitelist',
            sort: false,
        },
        {
            dataField: 'blacklisted',
            text: 'Blacklist',
            sort: false,
        },
    ]

    /**
     * Render the access control through Table component
     */
    return (
        <Box m="20px">
            <Header title="Access Controls" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`access-control`}
                change={change}
                columns={columns}
                customSearchOptions={customSearchOptions}
                customSearchOptionsRenderer={customSearchOptionsRenderer()}
                resetCustomSearchOptions={setCustomSearchOptions}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={2}
                disabledMatchAll={true}
                createPageUrl="/security/access/create"
                expandRow={expandRow}
            />
        </Box>
    )
}

export default SecurityAccessControl
