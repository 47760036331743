import store from '../redux/store'
import SecureAxios from '../extensions/SecureAxios'

const buildContentUrl = (url, searchOptions) => {
    let encodedKeyword = encodeURIComponent(searchOptions.keyword);
    let contentUrl = `${url}?keyword=${encodedKeyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`

    if (store.getState().switchTenant?.tenant?.id) {
        contentUrl += `&tenantId=${store.getState().switchTenant?.tenant.id}`
    }

    if (searchOptions.customSearchOptions?.state?.length) {
        contentUrl += `&filteredByState=${searchOptions.customSearchOptions.state.join(
            ','
        )}`
    }

    contentUrl += `&developmentStageLegacy=${searchOptions.customSearchOptions.stage}`
    switch (searchOptions.customSearchOptions?.stage) {
        case 'PREGNANCY':
            if (searchOptions.customSearchOptions?.week) {
                contentUrl += `&filteredByWeek=${searchOptions.customSearchOptions.week}`
            }
            break
        case 'INFANCY':
            if (searchOptions.customSearchOptions?.month) {
                const month = searchOptions.customSearchOptions.month

                const weekFrom = Math.round(40 + ((month - 1) * 4.34524) + 1)
                const weekTo = Math.round(40 + (month * 4.34524))

                // Convert week range to week, e.g, 41 to 44 = 41,42,43,44
                const weekRange = Array.from({length: weekTo - weekFrom + 1}, (_, i) => weekFrom + i)
                contentUrl += `&filteredByWeek=${weekRange.join(",")}`
            }
            break
        default:
            break
    }

    if (searchOptions.customSearchOptions?.filteredBySelectedDays?.length) {
        contentUrl += `&filteredBySelectedDays=${searchOptions.customSearchOptions.filteredBySelectedDays.join(
            ','
        )}`
    }

    if (searchOptions.customSearchOptions?.filteredByTagIds?.length) {
        contentUrl += `&filteredByTagIds=${searchOptions.customSearchOptions.filteredByTagIds.join(
            ','
        )}`
        if (searchOptions.customSearchOptions?.filteredByUpcomingWeekDuration) {
            contentUrl += `&filteredByUpcomingWeekDuration=${searchOptions.customSearchOptions.filteredByUpcomingWeekDuration}`
        }
    }

    if (searchOptions.customSearchOptions?.filteredByContentTypeIds?.length) {
        contentUrl += `&filteredByContentTypeIds=${searchOptions.customSearchOptions.filteredByContentTypeIds.join(
            ','
        )}`
    }

    if (searchOptions.customSearchOptions?.locale) {
        contentUrl += `&locale=${searchOptions.customSearchOptions.locale}`
    }

    if (searchOptions.customSearchOptions?.translateToPreferLocale) {
        contentUrl += `&translateToPreferLocale=${searchOptions.customSearchOptions.translateToPreferLocale}`
    }

    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        // Server is expected to be sorted by "due" if it is a week instead
        const sort =
            searchOptions.sortOptions.sortField === 'week'
                ? 'due'
                : searchOptions.sortOptions.sortField
        contentUrl += `&sort=${sort}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    return contentUrl
}

/**
 * An action to fetch contents from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchContents = async (searchOptions) => {
    const { data } = await SecureAxios.get(buildContentUrl('/consumer/v3/cms/contents', searchOptions))
    return data
}

/**
 * Generate Audit Report
 * @param searchOptions
 * @returns {Promise<any>}
 */
export const generateContentReport = async (searchOptions) => {
    const { data } = await SecureAxios.get(buildContentUrl('/consumer/v3/cms/content/csv/report', searchOptions))
    return data
}

/**
 * An action to fetch content types from neuro api
 */
export const fetchContentTypes = async () => {
    const { data } = await SecureAxios.get(`/consumer/cms/content/type`)
    return data.data.content
}

/**
 * An action to fetch content type tfs from neuro api
 */
export const fetchContentTypeTfByType = async (type) =>  {
    const { data } = await SecureAxios.get(`/consumer/cms/content/type/tf?type=${type}`)
    return data.data
}

/**
 * An action to fetch content type tfs from neuro api
 */
export const fetchAllContentTypeTfs = async () =>  {
    const { data } = await SecureAxios.get(`/consumer/cms/content/type/tf`)
    return data.data
}

/**
 * An action to fetch tags from neuro api
 */
export const fetchTags = async () => {
    const { data } = await SecureAxios.get(`/consumer/cms/tag`)
    return data.data.content
}

/**
 * Action to generate external content topic by type and content id
 */
export const generateExternalContentTopic = async (externalContentTopic) => {
    const requestBody = {
        type: externalContentTopic.type,
        contentId: externalContentTopic.contentId
    }
    const {data} = await SecureAxios.post(`/consumer/cms/external/content/topic/generate`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    return data.data
}

/**
 * Action to update content as main priority order
 * @param content
 * @returns {Promise<void>}
 */
export const updateMainPriorityOrder = async (content) => {
    const requestBody = {
        priorityOrder: 1,
        reset: true
    }

    await SecureAxios.post(`/consumer/cms/content/${content.id}/priority/order`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        })
}