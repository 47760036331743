import {Box, TextField} from '@mui/material'
import React, {FunctionComponent, useState} from 'react'
import Image from '../../../../components/Image'
import RichTextAreaEditor from '../../../../components/RichTextAreaEditor'
import AutocompleteExt from '../../../../components/Autocomplete'
import {ContentAssist, ContentAssistWrapper} from '../../../../interfaces/ContentAssistType'
import {ApiError} from "../../../../interfaces/ErrorType";
import {updateMainPriorityOrder} from "../../../../actions/contentAssists";
import PlayerExt from "../../../../components/Player";
import SwitchExt from "../../../../components/Switch";
import {useMutation} from "react-query";
import { v4 as uuidv4 } from 'uuid'
import TagInputExt from "../../../../components/TagInput";
import {toMonths} from "../../../../share/MonthConverter";
import {toDays} from "../../../../share/DayConverter";

const ContentAssistDetail: FunctionComponent<ContentAssistWrapper> = ({
                                                                                  wrapper,
                                                                                  callback
                                                                              }) => {

    const [content] = useState<ContentAssist>(wrapper!!)

    /**
     * Mutate priority order of a content
     */
    const mainPriorityOrderUpdateMutation = useMutation<any, ApiError, ContentAssist>(
        updateMainPriorityOrder,
        {
            onSuccess: () => {
                if (callback) {
                    callback(uuidv4())
                }
            },
        }
    )

    return (
        <Box>
            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0,1fr))"
                sx={{
                    '& > div': { gridColumn: ' span 2' },
                }}
            >
                <TextField
                    variant="filled"
                    type="text"
                    label="Id"
                    value={content.id}
                    name="id"
                />

                <TextField
                    variant="filled"
                    type="number"
                    label="Month"
                    value={content.week > 40 ? toMonths(toDays(content.week, content.day)!!)!! - 9 : toMonths(toDays(content.week, content.day)!!)}
                    name="week"
                />

                <TextField
                    variant="filled"
                    type="number"
                    label="Week"
                    value={content.week}
                    name="week"
                />

                <TextField
                    variant="filled"
                    type="number"
                    label="Day of The Week"
                    value={content.day}
                    name="day"
                />

                <TextField
                    variant="filled"
                    type="number"
                    label="Days (Due)"
                    value={toDays(content.week, content.day)}
                    name="day"
                />

                <SwitchExt
                    name="priorityOrder"
                    label="Main"
                    value={content.priorityOrder === 1}
                />
            </Box>

            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(1, minmax(0,1fr))"
                sx={{
                    '& > div': { gridColumn: ' span 1' },
                }}
                style={{ paddingTop: `30px`, paddingBottom: `30px` }}
            >
                <TextField
                    variant="filled"
                    type="text"
                    label="Title"
                    value={content.title}
                    name="title"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Sub Title"
                    value={content.subTitle}
                    name="title"
                />

                <TagInputExt
                    name="referenceUrls"
                    label="References"
                    values={content.referenceUrls.map(
                        (reference) => {
                            return {
                                id: `${reference.text} ${reference.url}`,
                                text: `${reference.text} ${reference.url}`,
                            }
                        }
                    )}
                    editable={false}
                />

                <RichTextAreaEditor
                    label="Body"
                    value={content.body}
                    editable={false}
                />
            </Box>

            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(2, minmax(0,1fr))"
            >
                <AutocompleteExt
                    name="contentTypes"
                    multiSelection={true}
                    label="Content Types"
                    selectedValue={content.contentTypes.map((type) => type.id)}
                    options={content.contentTypes.map((type) => {
                        return {
                            label: type.type,
                            value: type.id,
                        }
                    })}
                    disableUnselectAll={true}
                />

                <AutocompleteExt
                    name="contentTags"
                    multiSelection={true}
                    label="Content Tags"
                    selectedValue={content.contentTags.map((tag) => tag.id)}
                    options={content.contentTags.map((tag) => {
                        return {
                            label: tag.name,
                            value: tag.id,
                        }
                    })}
                    disableUnselectAll={true}
                />

                <Image label="Media" url={content.media?.url} />

                <Image label="Banner" url={content.banner?.url} />

                {content.video?.url && (
                    <PlayerExt label="Video" url={content.video.url} />
                )}

                {content.audio?.url && (
                    <PlayerExt label="Audio" url={content.audio.url} />
                )}

                <TextField
                    variant="filled"
                    type="text"
                    label="Locale"
                    value={content.locale}
                    name="locale"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Created At"
                    value={content.createdAt}
                    name="createdAt"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Last Update"
                    value={content.updatedAt}
                    name="updatedAt"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="State"
                    value={content.state}
                    name="state"
                />
            </Box>

            {/*<Box*/}
            {/*    display="flex"*/}
            {/*    justifyContent="end"*/}
            {/*    mt="20px"*/}
            {/*    gap="20px"*/}
            {/*>*/}
            {/*    <ButtonExt*/}
            {/*        value={*/}
            {/*            mainPriorityOrderUpdateMutation.isLoading*/}
            {/*                ? 'Saving'*/}
            {/*                : 'Mark as Main Content'*/}
            {/*        }*/}
            {/*        onClickEvent={() => mainPriorityOrderUpdateMutation.mutate(content)}*/}
            {/*        disabled={*/}
            {/*            mainPriorityOrderUpdateMutation.isLoading ||*/}
            {/*            content.priorityOrder === 1*/}
            {/*        }*/}
            {/*    />*/}
            {/*</Box>*/}
        </Box>
    )
}

export default ContentAssistDetail
