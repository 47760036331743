import React, { FunctionComponent, useState } from 'react'
import { Box, InputLabel } from '@mui/material'
import Header from '../../../components/Header'
import {
    TenantSetting,
    TenantSettingWrapper,
} from '../../../interfaces/SettingType'
import { connect } from 'react-redux'
import TabsExt from '../../../components/Tabs'
import { useAuthQueryWithQueryFunction } from '../../../extensions/UseAuthQuery'
import { fetchMyUserSetting } from '../../../actions/user'
import DefaultTenantSettingDetail from './default'
import ContentAssistTenantSettingDetail from './content/assist'
import QnATenantSettingDetail from './qna'
import ErrorMessage from '../../../components/ErrorMessage'
import { ApiError } from '../../../interfaces/ErrorType'

const TenantSettingDetail: FunctionComponent<TenantSettingWrapper> = ({
    switchTenant,
}) => {
    /**
     * Fetch my current user setting
     */
    const { error, status } = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        TenantSetting
    >('myUserSetting', fetchMyUserSetting, {
        onSuccess(data) {
            setUserSetting(data)
        },
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const [userSetting, setUserSetting] = useState<TenantSetting | undefined>()

    /**
     * Page containing tenant setting details
     */
    return (
        <Box m="20px">
            <Header
                title={`${switchTenant ? 'Tenant' : 'User'} Setting`}
                subtitle={
                    switchTenant
                        ? `${switchTenant.name} (${switchTenant.id})`
                        : ''
                }
            />

            <Box style={{ marginBottom: `2em` }}>
                {status === 'loading' && <InputLabel>Loading...</InputLabel>}

                {status === 'error' && <ErrorMessage error={error} />}
            </Box>

            {userSetting && (
                <TabsExt
                    name="tenant-settings"
                    tabs={[
                        {
                            name: 'Default',
                            component: (
                                <DefaultTenantSettingDetail
                                    wrapper={{
                                        debugMode: userSetting.debugMode,
                                        countryMapping:
                                            userSetting.countryMapping,
                                    }}
                                    callback={setUserSetting}
                                />
                            ),
                        },
                        {
                            name: 'Q&A',
                            component: (
                                <QnATenantSettingDetail
                                    wrapper={{
                                        questionAnswerMapping:
                                            userSetting.questionAnswerMapping,
                                    }}
                                    callback={setUserSetting}
                                />
                            ),
                        },
                        {
                            name: 'Content Assist',
                            component: (
                                <ContentAssistTenantSettingDetail
                                    wrapper={{
                                        contentAssistMapping:
                                            userSetting.contentAssistMapping,
                                    }}
                                    callback={setUserSetting}
                                />
                            ),
                        },
                    ]}
                />
            )}
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { switchTenant: state.switchTenant.tenant }
}

export default connect(mapStateToProps)(TenantSettingDetail)
