import React, { FunctionComponent } from 'react'
import { useMutation } from 'react-query'
import { Alert, AlertTitle, Box, TextField } from '@mui/material'
import Header from '../../../components/Header'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
    GeneralSetting,
    GeneralSettingWrapper,
} from '../../../interfaces/SettingType'
import { updateGeneralSetting } from '../../../actions/generalSetting'
import { connect } from 'react-redux'
import ButtonExt from '../../../components/ButtonExt'

const generalSettingSchema = yup.object().shape({
    range: yup.object().shape({
        weekFrom: yup.number().required('required'),
        weekTo: yup.number().required('required'),
    }),
})

const GeneralSettingDetail: FunctionComponent<GeneralSettingWrapper> = ({
    generalSetting,
}) => {
    /**
     * Mutate the general setting upon update
     */
    const generalSettingMutation = useMutation<any, Error, GeneralSetting>(
        updateGeneralSetting
    )

    /**
     * Invoke an action to update general setting
     * @param {*} e - event
     */
    const onSave = (values: GeneralSetting) => {
        generalSettingMutation.mutate(values)
    }

    /**
     * Page containing general setting details
     */
    return (
        <Box m="20px">
            <Header title="General Setting" />

            <Box style={{ marginBottom: `2em` }}>
                {generalSettingMutation.isError && (
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {generalSettingMutation.error?.message}
                    </Alert>
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={generalSetting}
                validationSchema={generalSettingSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            <TextField
                                variant="filled"
                                type="number"
                                label="Default Week From"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.range.weekFrom}
                                name="range.weekFrom"
                                error={
                                    !!touched.range?.weekFrom &&
                                    !!errors.range?.weekFrom
                                }
                                helperText={
                                    touched.range?.weekFrom &&
                                    errors.range?.weekFrom
                                }
                            />

                            <TextField
                                variant="filled"
                                type="number"
                                label="Default Week To"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.range.weekTo}
                                name="range.weekTo"
                                error={
                                    !!touched.range?.weekTo &&
                                    !!errors.range?.weekTo
                                }
                                helperText={
                                    touched.range?.weekTo &&
                                    errors.range?.weekTo
                                }
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    generalSettingMutation.isLoading
                                        ? 'Saving...'
                                        : 'Save'
                                }
                                disabled={generalSettingMutation.isLoading}
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the current general setting through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { generalSetting: state.generalSetting.setting }
}

export default connect(mapStateToProps)(GeneralSettingDetail)
