import React, {useState} from 'react'
import { v4 as uuidv4 } from 'uuid'
import {publicApiOptions} from "../../../../../share/PublicApiRegionConstant";
import {fetchAllSettings, fetchContents} from "../../../../../actions/publicApi";
import {useQuery} from "react-query";
import DataGridFilter, {SearchOptionsProp} from "../../../../../components/DataGridFilter";
import {Alert, AlertTitle, Box, InputLabel, TextField} from "@mui/material";
import AutocompleteExt from "../../../../../components/Autocomplete";
import ContentAssistDetail from "../../../assist/detail";
import ButtonExt from "../../../../../components/ButtonExt";
import Header from "../../../../../components/Header";
import PublicApiContentAssistDetail from "./detail";

/**
 * Render Contents page
 *
 * @returns contents
 */
function PublicApiContentAssists() {
    const [weekUuid, setWeekUuid] = useState(uuidv4())

    const [tenantCredential, setTenantCredential] = useState<TenantCredential>({
        apiUrl: undefined,
        tenantId: undefined,
        tenantApiKey: undefined,
        authenticated: false,
        unsuccessful: false,
    })

    const [localeSetting, setLocaleSetting] = useState<LocaleSetting[]>()
    const [tagSetting, setTagSetting] = useState<TagSetting[]>()

    /**
     * Query all configuration from tenant setting
     */
    const allSettingQuery = useQuery<any, Error, any>(
        ['settings', tenantCredential],
        () => fetchAllSettings(tenantCredential),
        {
            refetchOnWindowFocus: false,
            enabled: false, // disable this query from automatically running
            retry: 0,
            retryDelay: 0,
            onSuccess(data) {
                setLocaleSetting(data.locales)
                setTagSetting(data.tags)
                setTenantCredential({
                    ...tenantCredential,
                    authenticated: true,
                    unsuccessful: false,
                })
                setCustomSearchOptions({
                    ...defaultCustomSearchOptions
                })
                setWeekUuid(uuidv4())
            },
            onError() {
                setLocaleSetting([])
                setTagSetting([])
                setTenantCredential({
                    ...tenantCredential,
                    authenticated: false,
                    unsuccessful: true,
                })
            },
        }
    )

    /**
     * Authenticate and retrieve tenant locale setting
     * @param {*} e - event
     */
    const onAuthenticate = (e: any) => {
        e.preventDefault() // Stop form submit
        allSettingQuery.refetch()
    }

    const defaultCustomSearchOptions = {
        week: 1,
        locale: undefined,
        tagIds: [],
        weekDuration: NaN
    }

    const [customSearchOptions, setCustomSearchOptions] =
        useState<CustomContentOptions>({
            ...defaultCustomSearchOptions,
        })

    const expandRow = (row: any) => (
        <PublicApiContentAssistDetail key={row.id} contentWrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchContents(tenantCredential, searchOptions)
    }

    const localeOptions = localeSetting?.map((locale: LocaleSetting) => {
        return {
            label: locale.default ? `${locale.name} (Default)` : locale.name,
            value: locale.code,
        }
    })

    const tagOptions = tagSetting?.map((tag: TagSetting) => {
        return {
            label: tag.name,
            value: tag.id,
        }
    })

    const customSearchOptionsRenderer = () => (
        <>
            <TextField
                key={weekUuid}
                type="number"
                variant="filled"
                name="week"
                value={customSearchOptions.week}
                label="Filtered by week..."
                InputProps={{
                    inputProps: {
                        min: 1,
                    },
                }}
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        week: parseInt(event.target.value),
                    })
                }}
            />

            <AutocompleteExt
                name="locale"
                multiSelection={false}
                label="Filtered by locale..."
                selectedValue={customSearchOptions.locale}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        locale: value,
                    })
                }}
                options={localeOptions}
            />

            <AutocompleteExt
                name="tagIds"
                multiSelection={true}
                label="Filtered by tags..."
                selectedValue={customSearchOptions.tagIds}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        tagIds: value,
                    })
                }}
                options={tagOptions}
                editable={customSearchOptions.week > 0}
            />

            <TextField
                type="number"
                variant="filled"
                name="weekDuration"
                value={customSearchOptions.weekDuration}
                label="Filtered by week duration..."
                InputProps={{
                    inputProps: {
                        min: 1,
                    },
                }}
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        weekDuration: parseInt(event.target.value),
                    })
                }}
                disabled={customSearchOptions.tagIds.length === 0}
            />
        </>
    )

    const columns = [
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
        },
        {
            dataField: 'week',
            text: 'Week',
            sort: true,
        },
        {
            dataField: 'day',
            text: 'Day',
        },
    ]

    /**
     * Page containing content
     */
    return (
        <>
            <form onSubmit={onAuthenticate}>
                {allSettingQuery.status === 'loading' && (
                    <Box margin="30px">
                        <InputLabel>Authenticating...</InputLabel>
                    </Box>
                )}

                {(allSettingQuery.status !== 'loading' && tenantCredential.unsuccessful) && (
                    <Alert severity="error">
                        <AlertTitle>Authentication failed</AlertTitle>
                    </Alert>
                )}

                <Box margin="30px" gap="30px" display="grid" gridTemplateColumns="1fr 1fr">
                    <TextField
                        type="text"
                        variant="filled"
                        name="tenantId"
                        value={tenantCredential.tenantId}
                        label="Tenant Id"
                        onChange={(event) => {
                            setTenantCredential({
                                ...tenantCredential,
                                tenantId: event.target.value,
                            })
                        }}
                        required={true}
                    />

                    <TextField
                        type="password"
                        variant="filled"
                        name="tenantId"
                        value={tenantCredential.tenantApiKey}
                        label="Tenant Api Key"
                        onChange={(event) => {
                            setTenantCredential({
                                ...tenantCredential,
                                tenantApiKey: event.target.value,
                            })
                        }}
                        required={true}
                    />

                    <AutocompleteExt
                        name="publicApi"
                        multiSelection={false}
                        label="Public API"
                        selectedValue={tenantCredential.apiUrl}
                        onSelect={(value) => {
                            setTenantCredential({
                                ...tenantCredential,
                                apiUrl: value,
                            })
                        }}
                        options={publicApiOptions}
                        required={true}
                    />

                    <Box></Box>
                    <Box></Box>
                    <Box textAlign="right">
                        <ButtonExt
                            type="submit"
                            value="Authenticate"
                        />
                    </Box>
                </Box>
            </form>

            {tenantCredential.authenticated && (
                <Box m="30px">
                    <Header title="Content Assist" />
                    <DataGridFilter
                        keyField="id"
                        useQueryKey={`publicApiContentAssists`}
                        columns={columns}
                        onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                        searchFilterCols={3}
                        customSearchOptions={customSearchOptions}
                        resetCustomSearchOptions={setCustomSearchOptions}
                        customSearchOptionsRenderer={customSearchOptionsRenderer()}
                        disabledKeyword={true}
                        expandRow={expandRow}
                    />
                </Box>
            )}
        </>
    )
}

interface TenantCredential {
    apiUrl: string | undefined
    tenantId: string | undefined
    tenantApiKey: string | undefined
    authenticated: boolean
    unsuccessful: boolean
}

interface LocaleSetting {
    id: string
    code: string
    name: string
    default: boolean
}

interface TagSetting {
    id: string
    name: string
    description: string
}

interface CustomContentOptions {
    week: number
    locale: string | undefined
    tagIds: string[]
    weekDuration: number
}

export default PublicApiContentAssists