import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import {
    createTenant,
    generateApiKey,
    switchCurrentTenant,
    unsetCurrentTenant,
    updateTenant,
} from '../../../../actions/tenant'
import { connect } from 'react-redux'
import { Tenant, TenantWrapper } from '../../../../interfaces/TenantType'
import { Box, IconButton, TextField } from '@mui/material'
import Header from '../../../../components/Header'
import { Formik } from 'formik'
import * as yup from 'yup'
import CheckboxExt from '../../../../components/Checkbox'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ApiError } from '../../../../interfaces/ErrorType'
import ErrorMessage from '../../../../components/ErrorMessage'
import ButtonExt from '../../../../components/ButtonExt'

const tenantSchema = yup.object().shape({
    name: yup.string().required('required'),
    email: yup.string().email('invalid email').required('required'),
    contact: yup.string().optional(),
    apiKey: yup.string().optional(),
    active: yup.boolean().optional(),
})

const TenantDetail: FunctionComponent<TenantWrapper> = ({
    switchTenant,
    isNew = false,
    wrapper,
}) => {
    const navigate = useNavigate()
    const [tenant, setTenant] = useState<Tenant>(
        wrapper
            ? wrapper
            : {
                  id: undefined,
                  name: '',
                  email: '',
                  contact: '',
                  apiKey: '',
                  active: true,
              }
    )

    /**
     * Mutate tenant create
     */
    const tenantCreateMutation = useMutation<Tenant, ApiError, Tenant>(
        createTenant,
        {
            onSuccess: (data) => {
                setTenant(data)
            },
        }
    )

    /**
     * Mutate tenant update
     */
    const tenantUpdateMutation = useMutation<Tenant, ApiError, Tenant>(
        updateTenant,
        {
            onSuccess: (data) => {
                setTenant(data)
            },
        }
    )

    /**
     * Mutate generate api key
     */
    const generateApiKeyMutation = useMutation<Tenant, Error, string>(
        generateApiKey,
        {
            onSuccess: (data) => {
                setTenant(data)
            },
        }
    )

    /**
     * Mutate switch tenant
     */
    const switchTenantMutation = useMutation<any, Error, Tenant>(
        switchCurrentTenant
    )

    /**
     * Mutate unset tenant
     */
    const unsetTenantMutation = useMutation<any, Error, any>(unsetCurrentTenant)

    if (tenantCreateMutation.isSuccess) {
        navigate(`/tenant`)
    }

    /**
     * Invoke an action to create/ update tenant
     * @param {*} e - event
     */
    const onSave = (values: Tenant) => {
        if (!values.id) {
            tenantCreateMutation.mutate(values)
        } else {
            tenantUpdateMutation.mutate(values)
        }
    }

    /**
     * Page containing tenant details
     */
    return (
        <Box m="20px">
            {isNew && (
                <>
                    <Box
                        display="flex"
                        justifyContent="start"
                        mt="20px"
                        style={{ padding: `10px` }}
                    >
                        <IconButton
                            color="secondary"
                            onClick={() => navigate(`/tenant`)}
                        >
                            <ArrowBackIcon /> Back
                        </IconButton>
                    </Box>

                    <Header title="Create Tenant" />
                </>
            )}

            <Box style={{ marginBottom: `2em` }}>
                {tenantCreateMutation.isError && (
                    <ErrorMessage error={tenantCreateMutation.error} />
                )}

                {tenantUpdateMutation.isError && (
                    <ErrorMessage error={tenantUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={tenant}
                validationSchema={tenantSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        {tenant.id && (
                            <Box
                                style={{ marginBottom: `2em` }}
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(1, minmax(0,1fr))"
                            >
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="API Key"
                                    value={tenant.apiKey ? tenant.apiKey : ''}
                                    name="apiKey"
                                    disabled={true}
                                />
                            </Box>
                        )}
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            {tenant.id && (
                                <TextField
                                    variant="filled"
                                    type="text"
                                    label="Id"
                                    value={values.id}
                                    name="id"
                                />
                            )}

                            <TextField
                                variant="filled"
                                type="text"
                                label="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                            />

                            <TextField
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                            />

                            <TextField
                                variant="filled"
                                type="text"
                                label="Contact"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.contact}
                                name="contact"
                                error={!!touched.contact && !!errors.contact}
                                helperText={touched.contact && errors.contact}
                            />

                            <CheckboxExt
                                name="active"
                                label="Active"
                                onBlurEvent={handleBlur}
                                onChangeEvent={handleChange}
                                value={values.active}
                                editable={tenant.id !== undefined}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    tenantCreateMutation.isLoading ||
                                    tenantUpdateMutation.isLoading
                                        ? 'Saving...'
                                        : 'Save'
                                }
                                disabled={
                                    tenantCreateMutation.isLoading ||
                                    tenantUpdateMutation.isLoading
                                }
                            />

                            {!isNew &&
                                (switchTenant == null ||
                                    switchTenant.id !== tenant.id) && (
                                    <>
                                        <ButtonExt
                                            value={
                                                switchTenantMutation.isLoading
                                                    ? 'Switching...'
                                                    : 'Switch'
                                            }
                                            disabled={
                                                switchTenantMutation.isLoading
                                            }
                                            onClickEvent={() => {
                                                switchTenantMutation.mutate(
                                                    tenant
                                                )
                                            }}
                                        />
                                    </>
                                )}

                            {!isNew &&
                                switchTenant != null &&
                                switchTenant.id === tenant.id && (
                                    <>
                                        <ButtonExt
                                            value={
                                                unsetTenantMutation.isLoading
                                                    ? 'Unset...'
                                                    : 'Unset'
                                            }
                                            disabled={
                                                unsetTenantMutation.isLoading
                                            }
                                            onClickEvent={() => {
                                                unsetTenantMutation.mutate({})
                                            }}
                                        />
                                    </>
                                )}

                            {tenant.id && (
                                <>
                                    <ButtonExt
                                        value={
                                            generateApiKeyMutation.isLoading
                                                ? 'Generating...'
                                                : 'Generate Api Key'
                                        }
                                        disabled={
                                            generateApiKeyMutation.isLoading
                                        }
                                        onClickEvent={() => {
                                            if (tenant.id != null) {
                                                generateApiKeyMutation.mutate(
                                                    tenant.id
                                                )
                                            }
                                        }}
                                    />
                                </>
                            )}
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { switchTenant: state.switchTenant.tenant }
}

export default connect(mapStateToProps)(TenantDetail)
