import React from 'react'
import {AccessControlGroup} from "../../../../interfaces/AccessControlType";
import {fetchAccessControlGroup} from "../../../../actions/security";
import DataGridFilter, {SearchOptionsProp} from "../../../../components/DataGridFilter";
import {Box} from "@mui/material";
import Header from "../../../../components/Header";
import SecurityAccessControlGroupDetail from "./detail";

/**
 * Security Access Control Group list
 *
 * @returns security access control list page
 */
function SecurityAccessControlGroup() {
    const expandRow = (row: AccessControlGroup) => (
        <SecurityAccessControlGroupDetail key={row.id} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchAccessControlGroup(searchOptions)
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
    ]

    /**
     * Render the access control group through Table component
     */
    return (
        <Box m="20px">
            <Header title="Access Control Group" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`access-control-group`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={3}
                disabledMatchAll={true}
                createPageUrl="/security/access/group/create"
                expandRow={expandRow}
            />
        </Box>
    )
}

export default SecurityAccessControlGroup