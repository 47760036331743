import React, { FunctionComponent, useRef, useState } from 'react'
import { htmlToMarkdown, markdownToHtml } from './parser/Parser'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Box, Button, FormHelperText, InputLabel } from '@mui/material'

const RichTextAreaEditor: FunctionComponent<RichTextAreaEditorProp> = ({
    label,
    required = false,
    editable = true,
    onBlurEvent,
    onChangeEvent,
    value,
    error,
    helperText,
}) => {
    const [isPreview, setIsPreview] = useState<boolean>(true)
    const [htmlValue, setHtmlValue] = useState<string>(
        markdownToHtml(value || '')
    )
    const [markdownValue, setMarkdownValue] = useState<string>(value || '')

    const reactQuillRef = useRef<ReactQuill>(null)

    /*
     * Custom toolbar component for markdown-preview switch
     */
    const CustomToolbar = () => (
        <Button
            style={{
                background: `#337ab7`,
                fontSize: `small`,
                margin: `5px`,
                padding: `0 10px`,
                float: `right`,
            }}
            value={`Switch to ${!isPreview ? `preview` : `markdown`}`}
            onClick={() => onSwitch()}
        />
    )

    const TOOLBAR_OPTIONS = [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'blockquote', 'link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['clean'],
    ]

    const onSwitch = () => {
        setIsPreview(!isPreview)
    }

    const onInternalBlurEvent = async (event: any) => {
        if (onBlurEvent) {
            onBlurEvent(event)
        }
    }

    const onInternalChangeEvent = (html: string) => {
        const markdown = htmlToMarkdown(html)
        setHtmlValue(html)
        setMarkdownValue(markdown)

        if (onChangeEvent) {
            onChangeEvent(markdown)
        }
    }

    return (
        <FormHelperText onBlur={onInternalBlurEvent} error={error}>
            <Box style={{ marginLeft: `1em` }}>
                {label && (
                    <InputLabel
                        style={{ fontSize: `x-small`, paddingBottom: `10px` }}
                    >
                        {label}
                    </InputLabel>
                )}
                <CustomToolbar />
                {isPreview && (
                    <ReactQuill
                        ref={reactQuillRef}
                        theme="snow"
                        modules={{
                            toolbar: {
                                container: TOOLBAR_OPTIONS,
                            },
                        }}
                        value={htmlValue}
                        onChange={onInternalChangeEvent}
                        readOnly={!editable}
                    />
                )}

                {!isPreview && (
                    <ReactQuill
                        ref={reactQuillRef}
                        theme="snow"
                        modules={{
                            toolbar: {
                                container: TOOLBAR_OPTIONS,
                            },
                        }}
                        value={markdownValue}
                        readOnly={true}
                    />
                )}
            </Box>

            {helperText}
        </FormHelperText>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface RichTextAreaEditorProp {
    label?: string
    value: string
    required?: boolean // TODO: this is not implemented yet
    editable?: boolean
    onBlurEvent?: (event: any) => void // Handle event upon onBlur
    onChangeEvent?: (v: string) => void // Handle event upon value change
    error?: boolean | undefined
    helperText?: React.ReactNode
}

export default RichTextAreaEditor
