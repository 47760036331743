import React from 'react'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../../components/DataGridFilter'
import Header from '../../../../components/Header'
import { Box } from '@mui/material'
import {fetchMobileUserSettings} from "../../../../actions/user";
import MobileUserSettingDetail from "./detail";
import {MobileUserSetting} from "../../../../interfaces/SettingType";

/**
 * NOTE: this is only for QA purpose
 * Mobile user settings
 *
 * @returns mobile user setting list page
 */
function MobileUserSettings() {
    const expandRow = (row: MobileUserSetting) => (
        <MobileUserSettingDetail key={row.email} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchMobileUserSettings(searchOptions)
    }

    const columns = [
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'pregnancyWeek',
            text: 'Pregnancy Week',
        },
        {
            dataField: 'childBirth',
            text: 'Child Birth',
        },
        {
            dataField: 'currentWeek',
            text: 'Current Week',
        },
    ]

    /**
     * Render the mobile user settings through Table component
     */
    return (
        <Box m="20px">
            <Header title="Mobile User Settings" />
            <DataGridFilter
                keyField="email"
                useQueryKey={`mobile-user-settings`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={3}
                disabledMatchAll={true}
                expandRow={expandRow}
            />
        </Box>
    )
}

export default MobileUserSettings