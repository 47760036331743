export const toWeek = (days: number): number | undefined => {
    if (days === null) {
        return undefined;
    }

    const dayOfWeek = days % 7;
    return (dayOfWeek > 0) ? (days / 7) + 1 : days / 7;
}

export const toDayOfWeek = (days: number): number | undefined => {
    if (days === null) {
        return undefined;
    }

    const dayOfWeek = days % 7;
    return (dayOfWeek > 0) ? dayOfWeek : 7;
}