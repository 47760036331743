import React, {FunctionComponent, useEffect, useState} from 'react'
import { useMutation } from 'react-query'
import { Box, InputLabel, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
    ContentAssistTenantMappingWrapper,
    ContentAssistTenantSetting,
    KeyPair,
    TenantSetting,
} from '../../../../../interfaces/SettingType'
import {
    contentSearchEngineOptions,
    daysOptions, supportedMediaTypeOptions,
} from '../../../../../share/ContentAssistConstant'
import { useAuthQueryWithQueryFunction } from '../../../../../extensions/UseAuthQuery'
import {
    fetchContentTypes,
    fetchTags,
} from '../../../../../actions/contentAssists'
import {
    ContentTag,
    ContentType,
} from '../../../../../interfaces/ContentAssistType'
import { updateUserSetting } from '../../../../../actions/user'
import AutocompleteExt from '../../../../../components/Autocomplete'
import CheckboxExt from '../../../../../components/Checkbox'
import ErrorMessage from '../../../../../components/ErrorMessage'
import { ApiError } from '../../../../../interfaces/ErrorType'
import ButtonExt from '../../../../../components/ButtonExt'

const contentAssistTenantSettingSchema = yup.object().shape({
    contentAssistMapping: yup.object().shape({
        mediaEnabled: yup.boolean().optional(),
        weeklySummary: yup.boolean().optional(),
        selectedDays: yup.array().optional(),
        searchEngine: yup.string().optional(),
        contentTypes: yup
            .array<KeyPair>()
            .of(
                yup.object().shape({
                    key: yup.string().required(),
                })
            )
            .optional(),
        contentTags: yup
            .array<KeyPair>()
            .of(
                yup.object().shape({
                    key: yup.string().required(),
                })
            )
            .optional(),
        defaultUpcomingWeekDuration: yup.number().optional(),
    }),
})

const ContentAssistTenantSettingDetail: FunctionComponent<
    ContentAssistTenantMappingWrapper
> = ({ wrapper, callback }) => {
    const [contentAssistTenantSetting, setContentAssistTenantSetting] =
        useState<ContentAssistTenantSetting>(
            wrapper
                ? wrapper
                : {
                      contentAssistMapping: {
                          mediaEnabled: true,
                          supportedMediaTypes: supportedMediaTypeOptions.map(supportedMediaTypeOptions => supportedMediaTypeOptions.value),
                          weeklySummary: true,
                          selectedDays: [],
                          searchEngine: contentSearchEngineOptions[0].value,
                          contentTypes: [],
                          contentTags: [],
                          defaultUpcomingWeekDuration: 4,
                      },
                  }
        )

    const [availableContentTypeOptions, setAvailableContentTypeOptions] = useState<any[]>([])

    /**
     * Fetch content type list
     */
    const contentTypeQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        ContentType[]
    >('contentTypes', fetchContentTypes, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    useEffect(() => {
        if (contentTypeQuery.data) {
            setAvailableContentTypeOptions(
                contentTypeQuery.data.map(
                    (contentType) => {
                        return {
                            value: contentType.id,
                            label: contentType.type,
                        }
                    }
                )
            )
        }
    }, [contentTypeQuery.data])

    /**
     * Fetch tag list
     * */
    const contentTagQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        ContentTag[]
    >('contentTags', fetchTags, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Mutate content assist tenant setting update
     */
    const contentAssistTenantSettingUpdateMutation = useMutation<
        TenantSetting,
        ApiError,
        ContentAssistTenantSetting
    >(updateUserSetting, {
        onSuccess: (data) => {
            callback(data)
        },
    })

    if (
        contentTypeQuery.status === 'loading' ||
        contentTagQuery.status === 'loading'
    ) {
        return <InputLabel>Loading...</InputLabel>
    }

    const availableTagOptions = contentTagQuery.data?.map((contentTag) => {
        return {
            value: contentTag.id,
            label: contentTag.name,
        }
    })

    /**
     * Invoke an action to update content assist tenant setting
     * @param {*} e - event
     */
    const onSave = (values: ContentAssistTenantSetting) => {
        contentAssistTenantSettingUpdateMutation.mutate(values)
    }

    /**
     * Page containing default tenant setting details
     */
    return (
        <Box m="20px">
            <Box style={{ marginBottom: `2em` }}>
                {contentTypeQuery.isError && (
                    <ErrorMessage error={contentTypeQuery.error} />
                )}

                {contentTagQuery.isError && (
                    <ErrorMessage error={contentTagQuery.error} />
                )}

                {contentAssistTenantSettingUpdateMutation.isError && (
                    <ErrorMessage
                        error={contentAssistTenantSettingUpdateMutation.error}
                    />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={contentAssistTenantSetting}
                validationSchema={contentAssistTenantSettingSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            <AutocompleteExt
                                name="contentAssistMapping.searchEngine"
                                multiSelection={false}
                                label="Search Engine"
                                selectedValue={
                                    values.contentAssistMapping?.searchEngine
                                }
                                options={contentSearchEngineOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    if (
                                        values.contentAssistMapping &&
                                        contentAssistTenantSetting.contentAssistMapping
                                    ) {
                                        setContentAssistTenantSetting({
                                            ...contentAssistTenantSetting,
                                            contentAssistMapping: {
                                                ...contentAssistTenantSetting.contentAssistMapping,
                                                searchEngine: v,
                                            },
                                        })
                                        values.contentAssistMapping = {
                                            ...values.contentAssistMapping,
                                            searchEngine: v,
                                        }
                                    }
                                }}
                            />

                            <AutocompleteExt
                                name="contentAssistMapping.contentTypes"
                                multiSelection={true}
                                label="Content Types"
                                selectedValue={values.contentAssistMapping?.contentTypes.map(
                                    (contentType) => contentType.key
                                )}
                                options={availableContentTypeOptions}
                                onBlurEvent={handleBlur}
                                onCreateAction={(v) => {
                                    return {
                                        label: v,
                                        value: v
                                    }
                                }}
                                onCreateActionCallback={(v: any) => {
                                    if (availableContentTypeOptions && values.contentAssistMapping) {
                                        setAvailableContentTypeOptions([
                                            ...availableContentTypeOptions,
                                            v
                                        ])
                                        values.contentAssistMapping = {
                                            ...values.contentAssistMapping,
                                            contentTypes: [...values.contentAssistMapping.contentTypes, {key: v.value, value: v.label}]
                                        }
                                    }
                                }}
                                onSelect={(v) => {
                                    if (
                                        values.contentAssistMapping &&
                                        contentAssistTenantSetting.contentAssistMapping
                                    ) {
                                        const selectedContentTypes = v.map(
                                            (each: any) => {
                                                return {
                                                    key: each,
                                                }
                                            }
                                        )
                                        setContentAssistTenantSetting({
                                            ...contentAssistTenantSetting,
                                            contentAssistMapping: {
                                                ...contentAssistTenantSetting.contentAssistMapping,
                                                contentTypes:
                                                    selectedContentTypes,
                                            },
                                        })
                                        values.contentAssistMapping = {
                                            ...values.contentAssistMapping,
                                            contentTypes: selectedContentTypes,
                                        }
                                    }
                                }}
                                required={true}
                            />

                            <AutocompleteExt
                                name="contentAssistMapping.contentTags"
                                multiSelection={true}
                                label="Content Tags"
                                selectedValue={values.contentAssistMapping?.contentTags.map(
                                    (contentTag) => contentTag.key
                                )}
                                options={availableTagOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    if (
                                        values.contentAssistMapping &&
                                        contentAssistTenantSetting.contentAssistMapping
                                    ) {
                                        const selectedContentTags = v.map(
                                            (each: any) => {
                                                return {
                                                    key: each,
                                                }
                                            }
                                        )
                                        setContentAssistTenantSetting({
                                            ...contentAssistTenantSetting,
                                            contentAssistMapping: {
                                                ...contentAssistTenantSetting.contentAssistMapping,
                                                contentTags:
                                                    selectedContentTags,
                                            },
                                        })
                                        values.contentAssistMapping = {
                                            ...values.contentAssistMapping,
                                            contentTags: selectedContentTags,
                                        }
                                    }
                                }}
                            />

                            <TextField
                                variant="filled"
                                type="number"
                                label="Default Upcoming Week Duration"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                    values.contentAssistMapping
                                        ?.defaultUpcomingWeekDuration
                                }
                                name="contentAssistMapping.defaultUpcomingWeekDuration"
                            />

                            <CheckboxExt
                                name="contentAssistMapping.mediaEnabled"
                                label="Media Enabled"
                                onBlurEvent={handleBlur}
                                onChangeEvent={handleChange}
                                value={
                                    values.contentAssistMapping?.mediaEnabled
                                }
                            />

                            {values.contentAssistMapping?.mediaEnabled && (
                                <AutocompleteExt
                                    name="contentAssistMapping.supportedMediaTypes"
                                    multiSelection={true}
                                    label="Supported Media Types"
                                    selectedValue={values.contentAssistMapping?.supportedMediaTypes}
                                    options={supportedMediaTypeOptions}
                                    onBlurEvent={handleBlur}
                                    onSelect={(v) => {
                                        if (
                                            values.contentAssistMapping &&
                                            contentAssistTenantSetting.contentAssistMapping
                                        ) {
                                            setContentAssistTenantSetting({
                                                ...contentAssistTenantSetting,
                                                contentAssistMapping: {
                                                    ...contentAssistTenantSetting.contentAssistMapping,
                                                    supportedMediaTypes: v,
                                                },
                                            })
                                            values.contentAssistMapping = {
                                                ...values.contentAssistMapping,
                                                supportedMediaTypes: v,
                                            }
                                        }
                                    }}
                                />
                            )}

                            <CheckboxExt
                                name="contentAssistMapping.weeklySummary"
                                label="Weekly Summary"
                                onBlurEvent={handleBlur}
                                onChange={(v) => {
                                    if (
                                        contentAssistTenantSetting.contentAssistMapping &&
                                        values.contentAssistMapping
                                    ) {
                                        // if weekly summary is checked, then reset selected days
                                        if (v) {
                                            setContentAssistTenantSetting({
                                                ...contentAssistTenantSetting,
                                                contentAssistMapping: {
                                                    ...contentAssistTenantSetting.contentAssistMapping,
                                                    selectedDays: [],
                                                },
                                            })
                                            values.contentAssistMapping = {
                                                ...values.contentAssistMapping,
                                                selectedDays: [],
                                            }
                                        }
                                    }
                                }}
                                onChangeEvent={handleChange}
                                value={
                                    values.contentAssistMapping?.weeklySummary
                                }
                            />

                            <AutocompleteExt
                                name="contentAssistMapping.selectedDays"
                                multiSelection={true}
                                label="Custom Selected Days"
                                selectedValue={
                                    values.contentAssistMapping?.selectedDays
                                }
                                options={daysOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    if (
                                        values.contentAssistMapping &&
                                        contentAssistTenantSetting.contentAssistMapping
                                    ) {
                                        setContentAssistTenantSetting({
                                            ...contentAssistTenantSetting,
                                            contentAssistMapping: {
                                                ...contentAssistTenantSetting.contentAssistMapping,
                                                selectedDays: v,
                                            },
                                        })
                                        values.contentAssistMapping = {
                                            ...values.contentAssistMapping,
                                            selectedDays: v,
                                        }
                                    }
                                }}
                                editable={
                                    values.contentAssistMapping &&
                                    !values.contentAssistMapping.weeklySummary
                                }
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    contentAssistTenantSettingUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    contentAssistTenantSettingUpdateMutation.isLoading
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}
export default ContentAssistTenantSettingDetail
