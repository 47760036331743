import SecureAxios from '../extensions/SecureAxios'
import store from '../redux/store'
import {
    SWITCH_TENANT,
    UNSET_TENANT,
    UPDATE_TABLE_DATA_ITEM,
    UPDATE_TENANT,
} from '../redux/actionType'

export const CURRENT_TENANT = 'CURRENT_TENANT'

/**
 * An action to fetch tenants from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchTenants = async (searchOptions) => {
    let tenantUrl = `/consumer/tenants?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        tenantUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }
    const { data } = await SecureAxios.get(tenantUrl)
    return data
}

/**
 * An action to update the current tenant
 */
export async function updateTenant(tenant) {
    const { data } = await SecureAxios.put(
        `/consumer/tenants/${tenant.id}`,
        tenant,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    if (store.getState().switchTenant?.tenant?.id === data.data.id) {
        store.dispatch({ type: UPDATE_TENANT, payload: tenant })
    }

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'tenants', keyField: 'id', data: data.data },
    })

    return data.data
}

/**
 * An action to create the current tenant
 */
export async function createTenant(tenant) {
    const { data } = await SecureAxios.post(`/consumer/tenants`, tenant, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}

/**
 * An action to generate new api key
 */
export async function generateApiKey(tenantId) {
    const { data } = await SecureAxios.post(
        `/consumer/tenants/${tenantId}/generate/apikey`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return data.data
}

/**
 * An action to switch current tenant
 */
export async function switchCurrentTenant(tenant) {
    store.dispatch({ type: SWITCH_TENANT, payload: tenant })
}

/**
 * An action to switch current tenant
 */
export async function unsetCurrentTenant() {
    store.dispatch({ type: UNSET_TENANT, payload: undefined })
}
