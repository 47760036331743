import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { ColorModeContext, tokens } from '../../theme'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import SettingsIcon from '@mui/icons-material/Settings'
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility'
import FlagIcon from '@mui/icons-material/Flag'
import LanguageIcon from '@mui/icons-material/Language'
import {AuthToken, constructAuthRedirectUrl, signout, transit} from '../../actions/auth'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Tenant } from '../../interfaces/TenantType'
import AutocompleteExt from "../../components/Autocomplete";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import SecurityIcon from "@mui/icons-material/Security";
import ApprovalIcon from '@mui/icons-material/Approval';

const Topbar = (props: { user: AuthToken, switchTenant: Tenant }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext)
    const { user, switchTenant } = props
    const navigate = useNavigate()
    const [profileAnchorEl, setProfileAnchorEl] = useState(null)
    const [settingAnchorEl, setSettingAnchorEl] = useState(null)

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* Switch Bar */}
            <Box
                display="flex"
                borderRadius="3px"
                sx={{
                    backgroundColor: colors.primary[400],
                    width: `auto`,

                    "& .MuiFilledInput-root .MuiFilledInput-input": {
                        width: `100% !important`
                    },
                }}
            >
                <AutocompleteExt
                    name='dashboards'
                    multiSelection={false}
                    label='Switch Dashboard'
                    options={user?.user?.dashboards?.map(dashboard => {
                        return {
                            label: dashboard.name,
                            value: dashboard.url
                        }
                    })}
                    onSelect={async (v) => {
                        const data = await transit(user)
                        const authTransitRedirectUrl = constructAuthRedirectUrl(data.code, v + "/transit")
                        window.location.replace(authTransitRedirectUrl)
                    }}
                    editable={user?.user?.dashboards && user.user.dashboards.length > 0}
                />
            </Box>

            {/* Icons */}

            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>

                <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton>
                <IconButton
                    onClick={(e: any) => setSettingAnchorEl(e.currentTarget)}
                >
                    <SettingsOutlinedIcon />
                </IconButton>
                <Menu
                    id="setting-menu"
                    anchorEl={settingAnchorEl}
                    keepMounted
                    open={Boolean(settingAnchorEl)}
                    onClose={() => setSettingAnchorEl(null)}
                >
                    {(user?.user?.username && user?.user?.username !== 'aladdinsupport' && user?.user?.username && user?.user?.username !== 'aladdincontent') && (
                        <>
                            <Typography
                                variant="h6"
                                color={colors.grey[300]}
                                sx={{ m: '5px 0 5px 20px' }}
                            >
                                Config
                            </Typography>

                            <MenuItem onClick={() => navigate('/locale')} key="Locale">
                                <LanguageIcon /> &nbsp; &nbsp; Locale
                            </MenuItem>
                            <MenuItem
                                onClick={() => navigate('/country')}
                                key="Country"
                            >
                                <FlagIcon /> &nbsp; &nbsp; Country
                            </MenuItem>
                        </>
                    )}

                    {(user?.user?.username && user?.user?.username !== 'aladdinsupport' && user?.user?.username !== 'aladdincontent') && (
                        <>
                            <Typography
                                variant="h6"
                                color={colors.grey[300]}
                                sx={{ m: '5px 0 5px 20px' }}
                            >
                                Setting
                            </Typography>
                            <MenuItem
                                onClick={() => navigate('/setting/general')}
                                key="General"
                            >
                                <SettingsIcon /> &nbsp; &nbsp; General
                            </MenuItem>
                            <MenuItem
                                onClick={() => navigate('/setting/tenant')}
                                key={`${switchTenant ? 'Tenant' : 'User'}`}
                            >
                                <SettingsAccessibilityIcon /> &nbsp; &nbsp;{' '}
                                {`${switchTenant ? 'Tenant' : 'User'}`}
                            </MenuItem>
                        </>
                    )}

                    {user?.user?.username && user?.user?.username === 'aladdinsupport' && (
                        <>
                            <MenuItem
                                onClick={() => navigate('/setting/mobile/user')}
                                key="Mobile User">
                                <MobileFriendlyIcon /> &nbsp; &nbsp; Mobile User
                            </MenuItem>

                            <MenuItem
                                onClick={() => navigate('/setting/mobile/user/approval')}
                                key="Mobile User Approval">
                                <ApprovalIcon /> &nbsp; &nbsp; Mobile User Approval
                            </MenuItem>

                            <MenuItem
                                onClick={() => navigate('/setting/mobile/user/signup')}
                                key="Mobile Signup">
                                <MobileFriendlyIcon /> &nbsp; &nbsp; Mobile Signup
                            </MenuItem>

                            <Typography
                                variant="h6"
                                color={colors.grey[300]}
                                sx={{ m: '5px 0 5px 20px' }}
                            >
                                Security
                            </Typography>

                            <MenuItem
                                onClick={() => navigate('/security/access')}
                                key="Access Control">
                                <SecurityIcon /> &nbsp; &nbsp; Access Control
                            </MenuItem>
                        </>
                    )}
                </Menu>
                <IconButton
                    onClick={(e: any) => setProfileAnchorEl(e.currentTarget)}
                >
                    <PersonOutlinedIcon />
                </IconButton>
                <Menu
                    id="profile-menu"
                    anchorEl={profileAnchorEl}
                    keepMounted
                    open={Boolean(profileAnchorEl)}
                    onClose={() => setProfileAnchorEl(null)}
                >
                    <MenuItem onClick={() => signout()} key="Logout">
                        <ExitToAppIcon /> &nbsp; &nbsp; Logout
                    </MenuItem>
                </Menu>
            </Box>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user, switchTenant: state.switchTenant.tenant }
}

export default connect(mapStateToProps)(Topbar)
