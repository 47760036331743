import SecureAxios from "../extensions/SecureAxios";
import store from "../redux/store";
import {UPDATE_TABLE_DATA_ITEM, UPDATE_TENANT} from "../redux/actionType";

/**
 * An action to fetch access control from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchAccessControl = async (searchOptions) => {
    let accessControlSearchUrl = `/consumer/access/control?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`

    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        accessControlSearchUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    if (searchOptions.customSearchOptions?.filteredByGroupId) {
        accessControlSearchUrl += `&filteredByGroupId=${searchOptions.customSearchOptions.filteredByGroupId}`
    }

    const { data } = await SecureAxios.get(accessControlSearchUrl)
    return data
}

/**
 * An action to fetch all access control groups
 * @returns {Promise<*>}
 */
export const fetchAllAccessControlGroups = async () => {
    let accessControlGroupSearchUrl = `/consumer/access/control/group?keyword=&page=1&size=100000`
    const { data } = await SecureAxios.get(accessControlGroupSearchUrl)
    return data.data.content
}

/**
 * An action to fetch access control group from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchAccessControlGroup = async (searchOptions) => {
    let accessControlGroupSearchUrl = `/consumer/access/control/group?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`

    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        accessControlGroupSearchUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    const { data } = await SecureAxios.get(accessControlGroupSearchUrl)
    return data
}

/**
 * An action to update the access contorl
 */
export async function updateAccessControl(accessControl) {
    const { data } = await SecureAxios.post(
        `/consumer/access/control`,
        {
            id: accessControl.id,
            email: accessControl.email,
            accessControlGroupId: accessControl.accessControlGroupId,
            whitelisted: accessControl.whitelisted,
            blacklisted: accessControl.blacklisted,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'access-control', keyField: 'id', data: data.data },
    })

    return data.data
}

/**
 * An action to create or update the access control group
 */
export async function saveAccessControlGroup(accessControlGroup) {
    const requestBody = {
        ...accessControlGroup,
    }

    const { data } = await SecureAxios.post('/consumer/access/control/group', requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}

export async function blacklistAccessControlByGroupId(groupId) {
    await SecureAxios.post(`/consumer/access/control/group/${groupId}/blacklist`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

export async function whitelistAccessControlByGroupId(groupId) {
    await SecureAxios.post(`/consumer/access/control/group/${groupId}/whitelist`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}