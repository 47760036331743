import React, { useEffect, useRef, useState } from 'react'
import { Paper } from '@material-ui/core'
import { MessageLeft, MessageRight } from './Message'
import {Backdrop, Box, CircularProgress, InputLabel, useTheme} from '@mui/material'
import { connect } from 'react-redux'
import {UserProfile} from "../../../../../../interfaces/UserType";
import {tokens} from "../../../../../../theme";
import {useMutation} from "react-query";
import {VnExternalChatIdResponse, VnExternalChatResponse} from "../../../../../../interfaces/QnAType";
import {ApiError} from "../../../../../../interfaces/ErrorType";
import {initExternalQna, searchExternalQna} from "../../../../../../actions/qnaExperiment";
import InputSend from "./InputSend";
import ErrorMessage from "../../../../../../components/ErrorMessage";

const ExternalChatbot = (props: { childId: string | undefined, user: UserProfile }) => {
    const { user, childId } = props
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [chatId, setChatId] = useState<string>()
    const [messages, setMessages] = useState<Message[]>([])
    const messagesEndRef = useRef<HTMLDivElement>(null)

    /**
     * Init QnA
     */
    const qnaInitQuery = useMutation<VnExternalChatIdResponse, ApiError, string>(
        initExternalQna
    )

    /**
     * Reply QnA
     */
    const qnaReplyQuery = useMutation<VnExternalChatResponse, ApiError, any>(
        searchExternalQna
    )

    useEffect(() => {
        if (childId) {
            qnaInitQuery.mutate(
                childId,
                {
                    onSuccess: async (data) => {
                        console.log(data)
                        setMessages([])
                        setChatId(data.chatId)
                    },
                }
            )
        }
    }, [childId])

    useEffect(() => {
        scrollToBottom()
    }, [messages])

    const handleMessage = (v: string) => {
        const update = [...messages]
        update.push({ message: v, isBot: false })
        setMessages(update)
        qnaReplyQuery.mutate(
            { question: v, chatId: chatId, childId: childId },
            {
                onSuccess: async (data) => {
                    setMessages([
                        ...update,
                        { message: data.answer.origin, isBot: true },
                    ])
                },
            }
        )
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
        })
    }

    return (
        <Box
            sx={{
                '& .MuiPaper-root': {
                    backgroundColor:
                        theme.palette.mode === 'dark'
                            ? colors.grey[100]
                            : colors.primary[900],
                },

                '& .MuiPaper-MessageBody': {
                    width: 'calc( 100% - 20px )',
                    margin: 1,
                    overflowY: 'scroll',
                    height: '65vh',
                },

                '& .MuiBox-Container': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },

                '& .MuiBox-Panel': {
                    width: 'auto',
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                },
            }}
            className="MuiBox-Container"
        >
            {qnaReplyQuery.isError && (
                <ErrorMessage error={qnaReplyQuery.error} />
            )}
            <Paper className="MuiBox-Panel">
                <Paper className="MuiPaper-MessageBody">
                    {messages.map((message) => {
                        if (message.isBot) {
                            return (
                                <MessageLeft
                                    message={message.message}
                                    displayName="Bot"
                                />
                            )
                        }

                        if (!message.isBot) {
                            return (
                                <MessageRight
                                    message={message.message}
                                    displayName={user.name}
                                />
                            )
                        }
                    })}

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={qnaReplyQuery.isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <div ref={messagesEndRef} />
                </Paper>
                <InputSend onMessage={handleMessage} />
            </Paper>
        </Box>
    )
}

interface Message {
    message: string
    isBot: boolean
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user, switchTenant: state.switchTenant.tenant }
}

export default connect(mapStateToProps)(ExternalChatbot)