import React, { FunctionComponent, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { PriorityChainSetting } from './DndPriorityChainSettings'
import {Box, InputLabel} from '@mui/material'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import SwitchExt from "../Switch";

const DndPriorityChainSetting: FunctionComponent<PriorityChainSettingProp> = ({
    value,
    onValueChange,
    index,
    editable = true,
    onMoveItem,
}) => {
    // useDrag - the list item is draggable
    const [{ isDragging }, dragRef] = useDrag({
        type: 'item',
        item: { index },
        collect: (monitor: any) => ({
            isDragging: editable ? monitor.isDragging() : false,
        }),
    })

    // useDrop - the list item is also a drop area
    const [, dropRef] = useDrop({
        accept: 'item',
        hover: (item: any, monitor: any) => {
            if (editable) {
                const dragIndex = item.index
                const hoverIndex = index
                // @ts-ignore
                const hoverBoundingRect = ref.current?.getBoundingClientRect()
                const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
                const hoverActualY =
                    // @ts-ignore
                    monitor.getClientOffset().y - hoverBoundingRect.top

                // if dragging down, continue only when hover is smaller than middle Y
                if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY)
                    return
                // if dragging up, continue only when hover is bigger than middle Y
                if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY)
                    return

                if (onMoveItem) {
                    onMoveItem(dragIndex, hoverIndex)
                }
                item.index = hoverIndex
            }
        },
    })

    // Join the 2 refs together into one (both draggable and can be dropped on)
    const ref = useRef(null)
    const dragDropRef = dragRef(dropRef(ref)) as React.Ref<any>

    // Make items being dragged transparent, so it's easier to see where we drop them
    const opacity = isDragging ? 0 : 1
    return (
        <Box
            display="grid"
            gridTemplateColumns="30px 3fr 1fr"
            ref={dragDropRef}
            style={{ opacity }}
        >
            <DragHandleIcon style={{ cursor: `grab` }} />
            <InputLabel
                style={{
                    fontSize: `small`,
                }}
            >
                {value.label}
            </InputLabel>
            <SwitchExt
                name="enabled"
                value={value.enabled}
                onChange={(enabled) => {
                    onValueChange(
                        {
                            ...value,
                            enabled: enabled
                        }, index)
                }}
            />
        </Box>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface PriorityChainSettingProp {
    value: PriorityChainSetting
    onValueChange: (value: any, index: number) => void
    index: number
    editable?: boolean
    onMoveItem?: (dragIndex: number, hoverIndex: number) => void // Handle event upon onclick
}

export default DndPriorityChainSetting
