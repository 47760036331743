import React, { useState } from 'react'
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { tokens } from '../../theme'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import GroupsIcon from '@mui/icons-material/Groups'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import AssistantIcon from '@mui/icons-material/Assistant'
import ChatIcon from '@mui/icons-material/Chat'
import FlagIcon from '@mui/icons-material/Flag'
import LanguageIcon from '@mui/icons-material/Language'
import SettingsIcon from '@mui/icons-material/Settings'
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility'
import ClearIcon from '@mui/icons-material/Clear'
import PublicIcon from '@mui/icons-material/Public';
import SecurityIcon from '@mui/icons-material/Security';
import GroupIcon from '@mui/icons-material/Group';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import ApprovalIcon from '@mui/icons-material/Approval';
import { connect } from 'react-redux'
import { Tenant } from '../../interfaces/TenantType'
import { useMutation } from 'react-query'
import { unsetCurrentTenant } from '../../actions/tenant'
import {AuthToken} from "../../actions/auth";

interface ItemProps {
    title: string
    to: string
    icon: React.ReactNode
    selected: string
    setSelected: (title: string) => void
}

const Item = ({ title, to, icon, selected, setSelected }: ItemProps) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    return (
        <MenuItem
            active={selected === title}
            style={{ color: colors.grey[100] }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    )
}

const Sidebar = (props: { user: AuthToken; switchTenant: Tenant }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [selected, setSelected] = useState('Dashboard')
    const { user, switchTenant } = props

    /**
     * Mutate unset tenant
     */
    const unsetTenantMutation = useMutation<any, Error, any>(unsetCurrentTenant)

    return (
        <Box
            sx={{
                '& .pro-sidebar': {
                    minHeight: '100vh'
                },
                '& .pro-sidebar-inner': {
                    background: `${colors.primary[400]} !important`,
                },
                '& .pro-icon-wrapper': {
                    backgroundColor: 'transparent !important',
                },
                '& .pro-inner-item': {
                    padding: '5px 35px 5px 20px !important',
                },
                '& .pro-inner-item:hover': {
                    color: '#868dfb !important',
                },
                '& .pro-menu-item.active': {
                    color: '#6870fa !important',
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* Logo and Menu Items */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: '10px 0 10px 0',
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <img
                                    alt="Neurofrog"
                                    width="auto"
                                    height="auto"
                                    src={`/neurofrog.png`}
                                />
                                <Typography
                                    variant="h3"
                                    color={colors.grey[100]}
                                >
                                    Platform
                                </Typography>
                                <IconButton
                                    onClick={() => setIsCollapsed(!isCollapsed)}
                                >
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>
                    {/* User */}
                    {!isCollapsed && (
                        <Box mb="10px">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={`/assets/user.png`} //ref: https://icons8.com/icon/z-JBA_KtSkxG/test-account
                                />
                            </Box>

                            <Box textAlign="center">
                                <Typography
                                    variant="h4"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{ m: '10px 0 0 0' }}
                                >
                                    {user?.user?.username ? user?.user?.username : user?.user?.preferName}
                                </Typography>
                                {switchTenant && (
                                    <Typography
                                        variant="h5"
                                        title={`Id: ${switchTenant.id}`}
                                        color={colors.greenAccent[500]}
                                    >
                                        Tenant - {switchTenant.name}
                                        <IconButton
                                            disabled={
                                                unsetTenantMutation.isLoading
                                            }
                                            onClick={() => {
                                                unsetTenantMutation.mutate({})
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )}

                    {/* Menu Items */}
                    <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                        <Item
                            title="Dashboard"
                            to="/"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        {user?.user?.username && user?.user?.username !== 'aladdinsupport' && (
                            <>
                                <Typography
                                    variant="h6"
                                    color={colors.grey[300]}
                                    sx={{ m: '5px 0 5px 20px' }}
                                >
                                    Content
                                </Typography>

                                <Item
                                    title="Assist"
                                    to="/content/assist"
                                    icon={<AssistantIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                {user?.user?.username !== 'aladdincontent' && (
                                    <Item
                                        title="Public API"
                                        to="/content/assist/public/api"
                                        icon={<PublicIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                )}

                                <Typography
                                    variant="h6"
                                    color={colors.grey[300]}
                                    sx={{ m: '5px 0 5px 20px' }}
                                >
                                    Q & A
                                </Typography>

                                <Item
                                    title="Chatbot (Experiment)"
                                    to="/qna/experiment/chat"
                                    icon={<ChatIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Item
                                    title="Ext Chatbot (Experiment)"
                                    to="/qna/experiment/external/chat"
                                    icon={<ChatIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                {user?.user?.username !== 'aladdincontent' && (
                                    <>
                                        <Typography
                                            variant="h6"
                                            color={colors.grey[300]}
                                            sx={{ m: '5px 0 5px 20px' }}
                                        >
                                            Client
                                        </Typography>

                                        <Item
                                            title="Tenant"
                                            to="/tenant"
                                            icon={<GroupsIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />

                                        <Typography
                                            variant="h6"
                                            color={colors.grey[300]}
                                            sx={{ m: '5px 0 5px 20px' }}
                                        >
                                            Config
                                        </Typography>

                                        <Item
                                            title="Locale"
                                            to="/locale"
                                            icon={<LanguageIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />

                                        <Item
                                            title="Country"
                                            to="/country"
                                            icon={<FlagIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                    </>
                                )}
                            </>
                        )}

                        {user?.user?.username !== 'aladdincontent' && (
                            <Typography
                                variant="h6"
                                color={colors.grey[300]}
                                sx={{ m: '15px 0 5px 20px' }}
                            >
                                Setting
                            </Typography>
                        )}

                        {(user?.user?.username && user?.user?.username !== 'aladdinsupport' && user?.user?.username && user?.user?.username !== 'aladdincontent') && (
                            <>
                                <Typography
                                    variant="h6"
                                    color={colors.grey[300]}
                                    sx={{ m: '15px 0 5px 20px' }}
                                >
                                    Setting
                                </Typography>
                                <Item
                                    title="General"
                                    to="/setting/general"
                                    icon={<SettingsIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Item
                                    title={`${switchTenant ? 'Tenant' : 'User'}`}
                                    to="/setting/tenant"
                                    icon={<SettingsAccessibilityIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </>
                        )}

                        {user?.user?.username !== 'aladdincontent' && (
                            <>
                                <Item
                                    title="Mobile User"
                                    to="/setting/mobile/user"
                                    icon={<MobileFriendlyIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Item
                                    title="Mobile User Approval"
                                    to="/setting/mobile/user/approval"
                                    icon={<ApprovalIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Item
                                    title="Mobile Signup"
                                    to="/setting/mobile/user/signup"
                                    icon={<MobileFriendlyIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Typography
                                    variant="h6"
                                    color={colors.grey[300]}
                                    sx={{ m: '15px 0 5px 20px' }}
                                >
                                    Security
                                </Typography>

                                <Item
                                    title={`Group`}
                                    to="/security/access/group"
                                    icon={<GroupIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Item
                                    title={`Access Control`}
                                    to="/security/access"
                                    icon={<SecurityIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </>
                        )}
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user, switchTenant: state.switchTenant.tenant }
}

export default connect(mapStateToProps)(Sidebar)
