import SecureAxios from '../extensions/SecureAxios'
import store from '../redux/store'
import {UPDATE_TABLE_DATA_ITEM} from "../redux/actionType";

/**
 * An action to fetch my user setting
 */
export async function fetchMyUserSetting() {
    let getUserSettingUrl = '/consumer/users/setting'

    if (store.getState().switchTenant?.tenant?.id) {
        getUserSettingUrl += `?tenantId=${
            store.getState().switchTenant?.tenant?.id
        }`
    }
    const { data } = await SecureAxios.get(getUserSettingUrl)
    return data.data
}

/**
 * An action to fetch my available tags
 */
export async function fetchMyTags() {
    let getUserSettingUrl = '/consumer/users/setting'

    if (store.getState().switchTenant?.tenant?.id) {
        getUserSettingUrl += `?tenantId=${
            store.getState().switchTenant?.tenant?.id
        }`
    }
    const { data } = await SecureAxios.get(getUserSettingUrl)
    return data.data.contentAssistMapping.contentTags.map((contentTag) => {
        return {
            id: contentTag.key,
            name: contentTag.values[0],
            description: contentTag.values[1],
        }
    })
}

/**
 * An action to update user setting
 */
export async function updateUserSetting(userSetting) {
    let updateUserSettingUrl = '/consumer/users/setting'

    if (store.getState().switchTenant?.tenant?.id) {
        updateUserSettingUrl += `?tenantId=${
            store.getState().switchTenant?.tenant?.id
        }`
    }

    const requestBody = {
        ...userSetting,
    }

    const { data } = await SecureAxios.put(updateUserSettingUrl, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    return data.data
}

/**
 * An action to fetch mobile user settings from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchMobileUserSettings = async (searchOptions) => {
    let mobileUserSettingUrl = `/consumer/mobile/user/settings?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        mobileUserSettingUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }
    const { data } = await SecureAxios.get(mobileUserSettingUrl)
    return data
}

export const fetchMobileUserApprovalSettings = async (searchOptions) => {
    let mobileUserApprovalSettingUrl = `/consumer/users/access/controls?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        mobileUserApprovalSettingUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    if (searchOptions.customSearchOptions?.dateFrom) {
        mobileUserApprovalSettingUrl += `&dateFrom=${searchOptions.customSearchOptions.dateFrom}`
    }

    if (searchOptions.customSearchOptions?.dateTo) {
        mobileUserApprovalSettingUrl += `&dateTo=${searchOptions.customSearchOptions.dateTo}`
    }

    if (searchOptions.customSearchOptions?.filteredByGroupId) {
        mobileUserApprovalSettingUrl += `&filteredByGroupId=${searchOptions.customSearchOptions.filteredByGroupId}`
    }

    const { data } = await SecureAxios.get(mobileUserApprovalSettingUrl)
    return data
}

/**
 * An action to update mobile user setting
 */
export async function updateMobileUserSetting(mobileUserSetting) {
    let updateUserSettingUrl = '/consumer/mobile/user/settings'

    const { data } = await SecureAxios.post(updateUserSettingUrl, mobileUserSetting, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'mobile-user-settings', keyField: 'email', data: data.data },
    })

    return data.data
}

export async function mobileSignup(mobileSignup) {
    let mobileSignupUrl = '/consumer/admin/auth/signup'

    await SecureAxios.post(mobileSignupUrl, mobileSignup, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

