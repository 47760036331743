import React from 'react'
import { fetchTenants } from '../../../actions/tenant'
import TenantDetail from './detail'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../components/DataGridFilter'
import Header from '../../../components/Header'
import { Box } from '@mui/material'
import { Tenant } from '../../../interfaces/TenantType'

/**
 * Tenant list
 *
 * @returns tenant list page
 */
function Tenants() {
    const expandRow = (row: Tenant) => (
        <TenantDetail key={row.id} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchTenants(searchOptions)
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'email',
            text: 'Email',
        },
        {
            dataField: 'contact',
            text: 'Contact',
        },
        {
            dataField: 'active',
            text: 'Active',
        },
    ]

    /**
     * Render the tenants through Table component
     */
    return (
        <Box m="20px">
            <Header title="Tenants" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`tenants`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={3}
                disabledMatchAll={true}
                createPageUrl="/tenant/create"
                expandRow={expandRow}
            />
        </Box>
    )
}

export default Tenants
