import React, {useEffect, useState} from 'react'
import { Box } from '@mui/material'
import Header from "../../../../../components/Header";
import ExternalChatbot from "./bot/ExternalChatbot";
import {fetchAllChilds} from "../../../../../actions/child";
import {useAuthQueryWithQueryFunction} from "../../../../../extensions/UseAuthQuery";
import {ApiError} from "../../../../../interfaces/ErrorType";
import {UserChildResponse} from "../../../../../interfaces/ChildType";
import AutocompleteExt from "../../../../../components/Autocomplete";

function ExternalQnaChatExperiment() {

    const [loaded, setLoaded] = useState<boolean>(false)
    const [childId, setChildId] = useState<string | undefined>(undefined)
    const [childOptions, setChildOptions] = useState<any[]>()

    /**
     * Fetch my current user setting
     */
    const { refetch } = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        UserChildResponse[]
        >('fetchAllChilds', fetchAllChilds, {
        onSuccess(data) {
            setChildOptions(data.map((c) => ({ value: c.id, label: `${c.userDto.name ? c.userDto.name : c.userDto.id}, ${c.userDto.email}`})))
            setLoaded(true)
        },
        refetchOnWindowFocus: false,
        enabled: false,
    })

    useEffect(() => {
        if (!loaded) {
            refetch()
        }
    }, [loaded])

    return (
        <Box m="20px">
            <Header title="External Chatbot (Experiment)" />

            <AutocompleteExt
                name="childId"
                multiSelection={false}
                label="Child"
                selectedValue={childId}
                options={childOptions}
                onSelect={(v) => {
                    setChildId(v)
                }}
            />

            <ExternalChatbot childId={childId}/>
        </Box>
    )
}

export default ExternalQnaChatExperiment
