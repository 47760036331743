import React, { FunctionComponent, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import SendIcon from '@mui/icons-material/Send'
import ButtonExt from "../../../../../../components/ButtonExt";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapForm: {
            display: 'flex',
            justifyContent: 'center',
            width: '95%',
            margin: `${theme.spacing(0)} auto`,
        },
        wrapText: {
            width: '100%',
        },
        button: {
            margin: theme.spacing(1),
        },
    })
)

const InputSend: FunctionComponent<InputListener> = ({ onMessage }) => {
    const classes = useStyles()
    const [value, setValue] = useState<string>()

    const handleSend = () => {
        if (value) {
            onMessage(value)
            setValue('')
        }
    }

    return (
        <>
            <form className={classes.wrapForm} noValidate autoComplete="off">
                <TextField
                    id="standard-text"
                    type="text"
                    label="Write your message here"
                    value={value}
                    onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                            // Do code here
                            ev.preventDefault()
                            if (value) {
                                handleSend()
                            }
                        }
                    }}
                    onChange={(event) => setValue(event.target.value)}
                    className={classes.wrapText}
                />
                <ButtonExt
                    icon={<SendIcon />}
                    value={''}
                    onClickEvent={handleSend}
                    disabled={!value}
                    className={classes.button}
                />
            </form>
        </>
    )
}

interface InputListener {
    onMessage: (v: string) => void // Handle new message request
}

export default InputSend
