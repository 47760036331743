import { combineReducers } from 'redux'
import switchTenant from './reducer/tenant'
import tableData from './reducer/tableData'
import generalSetting from './reducer/generalSetting'
import countryList from './reducer/countries'
import localeList from './reducer/locales'
import tabsIndexes from './reducer/tabs'
import user from './reducer/user'

/**
 * Contains all the store reducers state.
 * Refer to store.js and /reducer/*
 */
export default combineReducers({
    user,
    switchTenant,
    tableData,
    generalSetting,
    countryList,
    localeList,
    tabsIndexes,
})
