import {toWeek} from "./WeekConverter";

export const toMonths = (days: number): number | undefined => {
    if (days === null) {
        return undefined;
    }

    const week = toWeek(days)!!;

    // Assuming a month is approximately 4.34524 weeks
    let month = Math.ceil(week / 4.34524);

    // Handle special case for values close to the boundary
    const threshold = 0.5;
    if (month > 1 && week % 4.34524 <= threshold) {
        month--;
    }

    return month;
}