import React, { useEffect, useRef, useState } from 'react'
import { Paper } from '@material-ui/core'
import { MessageLeft, MessageRight } from './Message'
import {Backdrop, Box, CircularProgress, InputLabel, useTheme} from '@mui/material'
import { tokens } from '../../../../../theme'
import { connect } from 'react-redux'
import { UserProfile } from '../../../../../interfaces/UserType'
import InputSend from './InputSend'
import {
    searchQna,
} from '../../../../../actions/qnaExperiment'
import {VnQnAMessageReply} from '../../../../../interfaces/QnAType'
import { useMutation } from 'react-query'
import { ApiError } from '../../../../../interfaces/ErrorType'
import ErrorMessage from '../../../../../components/ErrorMessage'
import { v4 as uuidv4 } from 'uuid'

const Chatbot = (props: { user: UserProfile }) => {
    const { user } = props
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [initialLaunch, setInitialLaunch] = useState<boolean>(false)
    const [key, setKey] = useState<string>()
    const [messages, setMessages] = useState<Message[]>([])
    const messagesEndRef = useRef<HTMLDivElement>(null)

    /**
     * Reply QnA
     */
    const qnaReplyQuery = useMutation<VnQnAMessageReply, ApiError, any>(
        searchQna
    )

    useEffect(() => {
        if (!initialLaunch) {
            setKey(uuidv4())
            setMessages([{ message: "To initiate your interaction with the experimental chatbot, please kick things off by sharing your inquiry related to a specific week. Feel free to provide any details or questions you have for that particular week. For instance, you can format it like this: \"Week 1 :: What's happening this week?\"", isBot: true }])
            setInitialLaunch(true)
        }
    }, [])

    useEffect(() => {
        scrollToBottom()
    }, [messages])

    const handleMessage = (v: string) => {
        const update = [...messages]
        update.push({ message: v, isBot: false })
        setMessages(update)
        qnaReplyQuery.mutate(
            { question: v, key: key },
            {
                onSuccess: async (data) => {
                    setMessages([
                        ...update,
                        { message: data.answer, isBot: true },
                    ])
                    if (data.activitiesLog) {
                        console.log('Activities Log')
                        console.log(data.activitiesLog)
                    }
                },
            }
        )
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
        })
    }

    return (
        <Box
            sx={{
                '& .MuiPaper-root': {
                    backgroundColor:
                        theme.palette.mode === 'dark'
                            ? colors.grey[100]
                            : colors.primary[900],
                },

                '& .MuiPaper-MessageBody': {
                    width: 'calc( 100% - 20px )',
                    margin: 1,
                    overflowY: 'scroll',
                    height: '65vh',
                },

                '& .MuiBox-Container': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },

                '& .MuiBox-Panel': {
                    width: 'auto',
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                },
            }}
            className="MuiBox-Container"
        >
            {qnaReplyQuery.isError && (
                <ErrorMessage error={qnaReplyQuery.error} />
            )}
            <Paper className="MuiBox-Panel">
                <Paper className="MuiPaper-MessageBody">
                    {messages.map((message) => {
                        if (message.isBot) {
                            return (
                                <MessageLeft
                                    message={message.message}
                                    displayName="Bot"
                                />
                            )
                        }

                        if (!message.isBot) {
                            return (
                                <MessageRight
                                    message={message.message}
                                    displayName={user.name}
                                />
                            )
                        }
                    })}

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={qnaReplyQuery.isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <div ref={messagesEndRef} />
                </Paper>
                <InputSend onMessage={handleMessage} />
            </Paper>
        </Box>
    )
}

interface Message {
    message: string
    isBot: boolean
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user, switchTenant: state.switchTenant.tenant }
}

export default connect(mapStateToProps)(Chatbot)
