import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Box, useTheme } from '@mui/material'
import { tokens } from '../theme'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'

const DatePickerExt: FunctionComponent<DatePickerProp> = ({
                                                              value,
                                                              min,
                                                              max,
                                                              label,
                                                              required = false,
                                                              editable = true,
                                                              onChange,
                                                          }) => {
    const DATE_FORMAT = 'YYYY-MM-DD'
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null)
    const uuid = useMemo(() => uuidv4(), [])
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    useEffect(() => {
        setSelectedDate(value ? dayjs(value) : null)
    }, [value])

    const onInternalChangeEvent = async (date: any) => {
        if (onChange) {
            const formattedDate = dayjs(date).format(DATE_FORMAT);
            onChange(formattedDate && formattedDate === 'Invalid Date' ? '' : formattedDate)
        }

        setSelectedDate(date)
    }

    return (
        <Box
            display='grid'
            sx={{
                '& .DatePicker-root': {
                    color: `${colors.greenAccent[200]} !important`,
                },

                '& .MuiTextField-root': {
                    width: `100%`,
                },
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker
                        key={uuid}
                        label={label}
                        value={selectedDate}
                        minDate={min ? dayjs(min) : undefined}
                        maxDate={max ? dayjs(max) : undefined}
                        onChange={onInternalChangeEvent}
                        readOnly={!editable}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </Box>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface DatePickerProp {
    label?: string | undefined
    value: string | undefined
    min?: string | undefined
    max?: string | undefined
    required?: boolean
    editable?: boolean
    onChange?: (v: string) => void // Handle event upon onclick
}

export default DatePickerExt