import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { Box, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { ApiError } from '../../../../../interfaces/ErrorType'
import ErrorMessage from '../../../../../components/ErrorMessage'
import ButtonExt from '../../../../../components/ButtonExt'
import {MobileUserSetting, MobileUserSettingWrapper} from "../../../../../interfaces/SettingType";
import {updateMobileUserSetting} from "../../../../../actions/user";
import DatePickerExt from "../../../../../components/DatePickerExt";

const mobileUserSettingSchema = yup.object().shape({
    email: yup.string().required('required'),
    pregnancyWeek: yup.string().optional().nullable(),
    childBirth: yup.string().optional(),
})

const MobileUserSettingDetail: FunctionComponent<MobileUserSettingWrapper> = ({
                                                            isNew = false,
                                                            wrapper,
                                                        }) => {
    const [mobileUserSetting, setMoileUserSetting] = useState<MobileUserSetting>(wrapper!!)

    /**
     * Mutate mobile user setting update
     */
    const mobileUserSettingUpdateMutation = useMutation<MobileUserSetting, ApiError, MobileUserSetting>(
        updateMobileUserSetting,
        {
            onSuccess: (data) => {
                setMoileUserSetting(data)
            },
        }
    )

    /**
     * Invoke an action to update mobile user setting
     * @param {*} e - event
     */
    const onSave = (values: MobileUserSetting) => {
        mobileUserSettingUpdateMutation.mutate(values)
    }

    function getCurrentDateInFormattedString() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    /**
     * Page containing mobile user setting details
     */
    // @ts-ignore
    return (
        <Box m="20px">
            <Box style={{ marginBottom: `2em` }}>
                {mobileUserSettingUpdateMutation.isError && (
                    <ErrorMessage error={mobileUserSettingUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={mobileUserSetting}
                validationSchema={mobileUserSettingSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            <TextField
                                variant="filled"
                                type="number"
                                label="Pregnancy Week"
                                onBlur={handleBlur}
                                onChange={(event) => {
                                    setMoileUserSetting({
                                        ...mobileUserSetting,
                                        pregnancyWeek: parseInt(event.target.value),
                                        childBirth: undefined
                                    })

                                    values.pregnancyWeek = parseInt(event.target.value)
                                    values.childBirth = undefined
                                }}
                                InputProps={{
                                    inputProps: {
                                        min: 1,
                                    },
                                }}
                                value={values.pregnancyWeek}
                                name="pregnancyWeek"
                                error={!!touched.pregnancyWeek && !!errors.pregnancyWeek}
                                helperText={touched.pregnancyWeek && errors.pregnancyWeek}
                            />

                            <DatePickerExt
                                label="Child Birth"
                                value={values.childBirth}
                                onChange={(value) => {
                                    setMoileUserSetting({
                                        ...mobileUserSetting,
                                        childBirth: value,
                                        pregnancyWeek: NaN,
                                    })

                                    values.childBirth = value
                                    values.pregnancyWeek = NaN
                                }}
                                max={getCurrentDateInFormattedString()}
                            />

                            <TextField
                                variant="filled"
                                type="number"
                                label="Current Week"
                                value={mobileUserSetting.currentWeek}
                                name="currentWeek"
                                disabled={true}
                            />

                            <TextField
                                variant="filled"
                                type="text"
                                label="Estimated Pregnant Date"
                                value={mobileUserSetting.estimatedPregnantDate}
                                name="estimatedPregnantDate"
                                disabled={true}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    mobileUserSettingUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    mobileUserSettingUpdateMutation.isLoading
                                    || (!values.pregnancyWeek && !values.childBirth)
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default MobileUserSettingDetail
