// User
export const UPDATE_USER = 'UPDATE_USER'
export const UNSET_UESR = 'UNSET_UESR'

// Tenant
export const SWITCH_TENANT = 'SWITCH_TENANT'
export const UPDATE_TENANT = 'UPDATE_TENANT'
export const UNSET_TENANT = 'UNSET_TENANT'

// Table Data
export const LOAD_TABLE_DATA = 'LOAD_TABLE_DATA'
export const UPDATE_TABLE_DATA_ITEM = 'UPDATE_TABLE_DATA_ITEM'
export const DELETE_TABLE_DATA_ITEM = 'DELETE_TABLE_DATA_ITEM'

// General Setting
export const LOAD_GENERAL_SETTING = 'LOAD_GENERAL_SETTING'
export const UPDATE_GENERAL_SETTING = 'UPDATE_GENERAL_SETTING'

// Country List
export const LOAD_COUNTRIES_LIST = 'LOAD_COUNTRY_LIST'
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES'

// Locale List
export const LOAD_LOCALES_LIST = 'LOAD_LOCALES_LIST'
export const UPDATE_LOCALES = 'UPDATE_LOCALES'

// Tabs
export const UPDATE_TAB_INDEX = 'UPDATE_TAB_INDEX'
