export const daysOptions = [
    {
        value: '1',
        label: '1',
    },

    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },

    {
        value: '4',
        label: '4',
    },

    {
        value: '5',
        label: '5',
    },
    {
        value: '6',
        label: '6',
    },

    {
        value: '7',
        label: '7',
    },
]

export const contentSearchEngineOptions = [
    {
        value: 'MONGO',
        label: 'Mongo',
    },

    {
        value: 'EASYGRAPH',
        label: 'Easygraph',
    },
]

export const supportedMediaTypeOptions = [
    {
        value: "IMAGE",
        label: "Image"
    },
    {
        value: "AUDIO",
        label: "Audio"
    },
    {
        value: "VIDEO",
        label: "Video"
    }
]

export const translateToPreferLocaleOptions = [
    {
        value: "en",
        label: "English"
    },
    {
        value: "vi",
        label: "Vietnamese"
    },
]

export const childStageOptions = [
    {
        value: "PREGNANCY",
        label: "Pregnancy"
    },
    {
        value: "INFANCY",
        label: "Infancy (1-12 months)"
    }
]