import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { Box, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { ApiError } from '../../../../../interfaces/ErrorType'
import ErrorMessage from '../../../../../components/ErrorMessage'
import ButtonExt from '../../../../../components/ButtonExt'
import {mobileSignup} from "../../../../../actions/user";

const mobileUserSignupSchema = yup.object().shape({
    username: yup.string().required('required'),
    password: yup.string().required('required'),
})

const MobileUserSignup: FunctionComponent<any> = () => {
    const [mobileUserSignup, setMobileUserSignup] = useState<any>({
        username: '',
        password: '1234567890'
    })

    /**
     * Mutate mobile user signup
     */
    const mobileUserSignupUpdateMutation = useMutation<any, ApiError, any>(
        mobileSignup
    )

    /**
     * Invoke an action to update mobile user signup
     * @param {*} e - event
     */
    const onSave = (values: any) => {
        mobileUserSignupUpdateMutation.mutate(values, {
            onSuccess: (data) => {
                alert('Mobile user signup has created for ' + mobileUserSignup.username + ' successfully.')

                setMobileUserSignup({
                    ...mobileUserSignup,
                    username: ''
                })
            }
        })
    }

    /**
     * Page containing mobile user signup details
     */
    return (
        <Box m="20px">
            <Box style={{ marginBottom: `2em` }}>
                {mobileUserSignupUpdateMutation.isError && (
                    <ErrorMessage error={mobileUserSignupUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={mobileUserSignup}
                validationSchema={mobileUserSignupSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(1, minmax(0,1fr))"
                        >
                            <TextField
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.username}
                                name="username"
                                error={!!touched.username && !!errors.username}
                            />

                            <TextField
                                variant="filled"
                                type="password"
                                label="Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                name="Password"
                                error={!!touched.password && !!errors.password}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    mobileUserSignupUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    mobileUserSignupUpdateMutation.isLoading
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default MobileUserSignup
