import React from 'react'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../../components/DataGridFilter'
import Header from '../../../../components/Header'
import { Box } from '@mui/material'
import { Locale } from '../../../../interfaces/LocalizationType'
import { fetchLocales } from '../../../../actions/localization'
import LocaleDetail from './detail'

/**
 * Locale list
 *
 * @returns locale list page
 */
function Locales() {
    const expandRow = (row: Locale) => (
        <LocaleDetail key={row.id} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchLocales(searchOptions)
    }

    const columns = [
        {
            dataField: 'code',
            text: 'Code',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
    ]

    /**
     * Render the countries through Table component
     */
    return (
        <Box m="20px">
            <Header title="Locales" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`locales`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={3}
                disabledMatchAll={true}
                createPageUrl="/locale/create"
                expandRow={expandRow}
            />
        </Box>
    )
}

export default Locales
