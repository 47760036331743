import store from '../redux/store'
import {
    LOAD_GENERAL_SETTING,
    UNSET_TENANT,
    UNSET_UESR,
} from '../redux/actionType'
import { fetchAllCountries, fetchAllLocales } from './localization'

export const loadCurrentConfigurations = () => {
    return async () => {
        store.dispatch({
            type: LOAD_GENERAL_SETTING,
        })

        if (store.getState().user && store.getState().user.user) {
            try {
                await fetchAllCountries()

                await fetchAllLocales()
            } catch (error) {
                console.log(error)
            }
        }
    }
}

export const removeAllConfigurations = () => {
    store.dispatch({
        type: UNSET_TENANT,
    })

    store.dispatch({
        type: UNSET_UESR,
    })
}
