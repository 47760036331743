import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { Box, TextField } from '@mui/material'
import {MobileUserApprovalSetting, MobileUserApprovalSettingWrapper} from "../../../../../../interfaces/SettingType";
import {AccessControl, AccessControlGroup} from "../../../../../../interfaces/AccessControlType";
import {ApiError} from "../../../../../../interfaces/ErrorType";
import {fetchAllAccessControlGroups, updateAccessControl} from "../../../../../../actions/security";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import ButtonExt from "../../../../../../components/ButtonExt";
import store from "../../../../../../redux/store";
import {UPDATE_TABLE_DATA_ITEM} from "../../../../../../redux/actionType";
import {useAuthQueryWithQueryFunction} from "../../../../../../extensions/UseAuthQuery";
import AutocompleteExt from "../../../../../../components/Autocomplete";

const MobileUserApprovalSettingsDetail: FunctionComponent<MobileUserApprovalSettingWrapper> = ({
                                                                                  wrapper,
                                                                              }) => {
    const [mobileUserApprovalSetting] = useState<MobileUserApprovalSetting>({
        id: wrapper!!.id,
        email: wrapper!!.email,
        name: wrapper!!.name,
        firstName: wrapper!!.firstName,
        lastName: wrapper!!.lastName,
        createdAt: wrapper!!.createdAt,
        accessControlGroupId: wrapper!!.accessControlGroupId,
        whitelisted: wrapper!!.whitelisted,
        blacklisted: wrapper!!.blacklisted,
        accessValidityTimeframe: wrapper!!.accessValidityTimeframe,
    })
    const [accessControl, setAccessControl] = useState<AccessControl>({
        id: undefined,
        email: wrapper!!.email,
        accessControlGroupId: mobileUserApprovalSetting.accessControlGroupId ?? '',
        whitelisted: wrapper!!.whitelisted ?? false,
        blacklisted: wrapper!!.blacklisted ?? false,
    })
    const [accessControlGroupOptions, setAccessControlGroupOptions] = useState<any[]>([])

    const isAccessible = (data: AccessControl) => {
        if (data.blacklisted) {
            return 'No'
        }

        if (data.whitelisted) {
            return 'Yes'
        }

        if (!mobileUserApprovalSetting.accessValidityTimeframe) {
            return 'No'
        }

        const currentDate = new Date();
        const providedDate = new Date(mobileUserApprovalSetting.accessValidityTimeframe!!);

        // Compare the dates
        if (providedDate > currentDate) {
            return 'Yes';
        } else {
            return 'No';
        }
    };

    const toPreferDateFormat = (date: string | undefined) => {
        if (date && date !== null) {
            const toDate = new Date(date!!);
            const formattedDate = `${toDate.getFullYear()}-${(toDate.getMonth() + 1).toString().padStart(2, '0')}-${toDate.getDate().toString().padStart(2, '0')} ${toDate.getHours().toString().padStart(2, '0')}:${toDate.getMinutes().toString().padStart(2, '0')}:${toDate.getSeconds().toString().padStart(2, '0')}`;
            return formattedDate;
        }

        return '-'
    }

    const [accessible, setAccessible] = useState<string>(isAccessible(accessControl))

    /**
     * Mutate access control update
     */
    const accessControlUpdateMutation = useMutation<AccessControl, ApiError, AccessControl>(
        updateAccessControl,
        {
            onSuccess: (data) => {
                store.dispatch({
                    type: UPDATE_TABLE_DATA_ITEM,
                    payload: { key: 'mobile-user-approval-settings', keyField: 'id', data: {...mobileUserApprovalSetting,
                            blacklisted: data.blacklisted,
                            whitelisted: data.whitelisted,
                        }},
                })
                setAccessControl(data)
                setAccessible(isAccessible(data))
            },
        }
    )

    /**
     * Fetch all access control groups query
     */
    const fetchAllAccessControlGroupsQuery = useAuthQueryWithQueryFunction<AccessControlGroup[], ApiError, AccessControlGroup[]>(
        'access-control-groups',
        fetchAllAccessControlGroups,
        {
            onSuccess: (data) => {
                setAccessControlGroupOptions(data.map((group) => ({
                    value: group.id,
                    label: group.name,
                })))
            },
            refetchOnWindowFocus: false,
            enabled: true,
        }
    )

    /**
     * Invoke an action to create/ update access control
     */
    const onSave = (values: AccessControl) => {
        accessControlUpdateMutation.mutate(values)
    }

    /**
     * Page containing access control details
     */
    return (
        <Box m="20px">
            <Box style={{ marginBottom: `2em` }}>
                {accessControlUpdateMutation.isError && (
                    <ErrorMessage error={accessControlUpdateMutation.error} />
                )}
            </Box>

            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(2, minmax(0,1fr))"
            >
                <TextField
                    variant="filled"
                    type="text"
                    label="Id"
                    value={mobileUserApprovalSetting.id}
                    name="id"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Name"
                    value={mobileUserApprovalSetting.name}
                    name="name"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Email"
                    value={mobileUserApprovalSetting.email}
                    name="email"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Sign Up Date"
                    value={toPreferDateFormat(mobileUserApprovalSetting.createdAt)}
                    name="createdAt"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Access Valid Until"
                    value={toPreferDateFormat(mobileUserApprovalSetting.accessValidityTimeframe)}
                    name="accessValidityTimeframe"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Accessible"
                    value={accessible}
                />

                <AutocompleteExt
                    name="accessControlGroupId"
                    multiSelection={false}
                    label="Access Control Group"
                    selectedValue={accessControl.accessControlGroupId}
                    onSelect={(value) => {
                        setAccessControl({
                            ...accessControl,
                            accessControlGroupId: value,
                        })
                    }}
                    options={accessControlGroupOptions}
                />
            </Box>
            {!mobileUserApprovalSetting.email.endsWith('@deleted.com') && (
                <Box
                    display="flex"
                    justifyContent="end"
                    mt="20px"
                    gap="20px"
                >
                    {(!accessControl.whitelisted || accessControl.blacklisted) && (
                        <ButtonExt
                            type="button"
                            value={'Approve'}
                            onClickEvent={() => {
                                accessControl.whitelisted = true
                                accessControl.blacklisted = false
                                onSave(accessControl)
                            }}
                            disabled={
                                accessControlUpdateMutation.isLoading
                            }
                        />
                    )}

                    {(!accessControl.blacklisted || accessControl.whitelisted) && (
                        <ButtonExt
                            type="button"
                            value={'Reject'}
                            onClickEvent={() => {
                                accessControl.whitelisted = false
                                accessControl.blacklisted = true
                                onSave(accessControl)
                            }}
                            disabled={
                                accessControlUpdateMutation.isLoading
                            }
                        />
                    )}
                </Box>
            )}
        </Box>
    )
}

export default MobileUserApprovalSettingsDetail