export const translationStrategyOptions = [
    {
        label: 'Aws',
        value: 'AWS',
    },
]

export const preferTranslationOption = [
    {
        label: 'Default',
        value: 'DEFAULT',
    },
    {
        label: 'Chinese',
        value: 'CHINESE',
    },
    {
        label: 'Malay',
        value: 'MALAY',
    },
]

export const chatbotStrategyOptions = [
    {
        label: 'ChatGPT',
        value: 'CHATGPT',
    },
    {
        label: 'Nlp Cloud',
        value: 'NLP_CLOUD',
    },
]

// For ChatGPT
export const chatGPTSearchEngineOptions = [
    {
        label: 'ChatGPT',
        value: 'CHATGPT',
    },
    {
        label: 'Pinecone',
        value: 'PINECONE',
    },
]

// For Nlp Cloud
export const nlpCloudSearchEngineOptions = [
    {
        label: 'Pinecone',
        value: 'PINECONE',
    },
    {
        label: 'Elastic',
        value: 'ELASTIC',
    },
]

export const nlpCloudGrammarSpellingCorrectionStrategyOptions = [
    {
        label: 'Nlp Cloud Grammer Spelling Correction',
        value: 'NLPCLOUD_GRAMMAR_SPELLING_CORRECTION',
    },
]

export const nlpCloudKeywordExtractStrategyOptions = [
    {
        label: 'Nlp Cloud',
        value: 'NLP_CLOUD',
    },
    {
        label: 'Rake Open Nlp',
        value: 'RAKE',
    },
]

export const nlpCloudExtractSimilarKeywordStrategyOptions = [
    {
        label: 'Deep Learning Similar Keyword',
        value: 'DEEP_LEARNING_SIMILAR_KEYWORD',
    },
]

export const nlpCloudSimilarityScoreStrategyOptions = [
    {
        label: 'Deep Learning Similarity',
        value: 'DEEP_LEARNING_SIMILARITY',
    },
    {
        label: 'Jaccard Index',
        value: 'JACCARD_SIMILARITY',
    },
    {
        label: 'Jaro Winkler Distance',
        value: 'JARO_WINKLER_DISTANCE',
    },
    {
        label: 'Nlp Cloud Semantic Similarity',
        value: 'NLP_CLOUD_SEMANTIC_SIMILARITY',
    },
]
