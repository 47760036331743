import React from 'react'
import { Box } from '@mui/material'
import Header from '../../../../components/Header'
import Chatbot from './bot/Chatbot'

function QnaChatExperiment() {
    return (
        <Box m="20px">
            <Header title="Chatbot (Experiment)" />
            <Chatbot />
        </Box>
    )
}

export default QnaChatExperiment
