import React, {FunctionComponent, useState} from 'react'
import { v4 as uuidv4 } from 'uuid'
import AddIcon from '@mui/icons-material/Add';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DeselectIcon from '@mui/icons-material/Deselect';
import {
    Autocomplete, Box,
    FormControl,
    FormHelperText, IconButton,
    TextField,
} from '@mui/material'

const AutocompleteExt: FunctionComponent<AutocompleteProp> = ({
    name,
    label,
    options,
    selectedValue,
    multiSelection = false,
    required = false,
    editable = true,
    onBlurEvent,
    onSelect,
    onCreateAction,
    onCreateActionCallback,
    disableSelectAll = false,
    disableUnselectAll = false,
    error,
    helperText,
}) => {
    const [change, setChange] = useState(uuidv4())
    let typingValue = ''

    const onInternalBlurEvent = async (event: any) => {
        if (onBlurEvent) {
            onBlurEvent(event)
        }
    }

    const onInternalSelectEvent = async (event: any, value: any) => {
        const convertValue = !value ? undefined : (multiSelection
            ? value.map((each: any) => each.value)
            : value.value)
        if (onSelect) {
            onSelect(convertValue)
        }
    }

    const onInternalCreateAction = async (value: any) => {
        if (onCreateAction && onCreateActionCallback) {
            const newOption = onCreateAction(value)
            if (newOption) {
                onCreateActionCallback(newOption)
                typingValue = ''
                setChange(uuidv4())
            }
        }
    }

    return (
        <FormHelperText key={change} onBlur={onInternalBlurEvent} error={error}>
            {((options && options.length > 0) || onCreateAction) && (
                <FormControl sx={{
                    "& .MuiAutocomplete-tag": {
                        margin: `0 !important`,
                    },

                    "& .MuiFilledInput-root": {
                        paddingRight: `0 !important`,
                    }
                }} key={change} fullWidth={true}>
                    <Autocomplete
                        fullWidth={true}
                        multiple={multiSelection}
                        value={
                            multiSelection
                                ? options?.filter((option) =>
                                      selectedValue
                                          .map(String)
                                          .includes(option.value)
                                  )
                                : (
                                    selectedValue ? (options?.find((option) => String(selectedValue) === option.value)) : ''
                                  )
                        }
                        onBlur={onInternalBlurEvent}
                        onChange={onInternalSelectEvent}
                        disabled={!editable}
                        filterSelectedOptions={true}
                        renderInput={(params) => (
                            <>
                                <Box display="grid" gridTemplateColumns="1fr">
                                    <TextField
                                        {...params}
                                        name={name}
                                        variant="filled"
                                        label={label}
                                        onChange={(event) => {
                                            typingValue = event.target.value
                                        }}
                                        required={
                                            required &&
                                            (multiSelection
                                                ? selectedValue &&
                                                selectedValue.length === 0
                                                : !selectedValue)
                                        }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {editable && onCreateAction && typingValue && !options?.find((each) => each.label.toLowerCase() === typingValue.toLowerCase()) && (
                                                        <IconButton onClick={() => onInternalCreateAction(typingValue)}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    )}

                                                    {editable && multiSelection && !disableSelectAll && selectedValue.length !== options?.length && (
                                                        <IconButton onClick={(event) => onInternalSelectEvent(event, options)}>
                                                            <SelectAllIcon />
                                                        </IconButton>
                                                    )}

                                                    {editable && multiSelection && !disableUnselectAll && selectedValue.length > 0 && (
                                                        <IconButton onClick={(event) => onInternalSelectEvent(event, [])}>
                                                            <DeselectIcon />
                                                        </IconButton>
                                                    )}

                                                    {editable && !multiSelection && !disableUnselectAll && selectedValue && (
                                                        <IconButton onClick={(event) => onInternalSelectEvent(event, undefined)}>
                                                            <DeselectIcon />
                                                        </IconButton>
                                                    )}
                                                </>
                                            )
                                        }}
                                    />
                                </Box>
                            </>
                        )}
                        options={options ? options : []}
                        disableClearable={true}
                    />
                </FormControl>
            )}

            {((!options || options.length === 0) && !onCreateAction) && (
                <TextField
                    fullWidth={true}
                    variant="filled"
                    type="text"
                    label={label}
                    value="Not Available"
                    name={name}
                    disabled={true}
                />
            )}

            {helperText}
        </FormHelperText>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface AutocompleteProp {
    name: string | undefined
    label?: string | undefined
    options: any[] | undefined
    selectedValue?: any | any[]
    multiSelection?: boolean
    disableSelectAll?: boolean
    disableUnselectAll?: boolean
    required?: boolean
    editable?: boolean
    onBlurEvent?: (event: any) => void // Handle event upon onBlur
    onSelect?: (v: any) => void // Handle event upon onSelect
    onCreateAction?: (v: any) => any // Handle on create action
    onCreateActionCallback?: (v: any) => void // Handle on create action callback
    error?: boolean | undefined
    helperText?: React.ReactNode
}

export default AutocompleteExt
