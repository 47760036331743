import React, { useCallback, FunctionComponent } from 'react'
import DndPriorityChainSetting from './DndPriorityChainSetting'
import { Box, InputLabel } from '@mui/material'

const DndPriorityChainSettings: FunctionComponent<
    PriorityChainSettingsProp
> = ({ caption, data, setData, editable = true }) => {
    const onMoveItem = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            if (data) {
                const dragItem = data[dragIndex]
                const hoverItem = data[hoverIndex]
                // Swap places of dragItem and hoverItem in the data array
                const updatedData = [...data]
                updatedData[dragIndex] = hoverItem
                updatedData[hoverIndex] = dragItem

                setData(updatedData)
            }
        },
        [data]
    )

    const onValueChange = (value: any, index: number) => {
        if (data) {
            const updatedData = [...data]
            updatedData[index] = value
            setData(updatedData)
        }
    }

    return (
        <>
            {caption && <InputLabel>{caption}</InputLabel>}
            <Box>
                {data?.map((each, index) => (
                    <DndPriorityChainSetting
                        key={each.value}
                        index={index}
                        value={each}
                        onValueChange={onValueChange}
                        onMoveItem={onMoveItem}
                        editable={editable}
                    />
                ))}
            </Box>
        </>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface PriorityChainSettingsProp {
    caption?: string | undefined
    data: PriorityChainSetting[] | undefined
    setData: (data: any[]) => void
    editable?: boolean
}

export interface PriorityChainSetting {
    value: string
    label: string
    enabled: boolean
}

export default DndPriorityChainSettings
