import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { Box, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
    QnATenantSetting,
    QnATenantSettingWrapper,
    TenantSetting,
} from '../../../../interfaces/SettingType'
import {
    nlpCloudExtractSimilarKeywordStrategyOptions,
    nlpCloudGrammarSpellingCorrectionStrategyOptions,
    nlpCloudKeywordExtractStrategyOptions,
    preferTranslationOption,
    nlpCloudSearchEngineOptions,
    nlpCloudSimilarityScoreStrategyOptions,
    translationStrategyOptions,
    chatbotStrategyOptions,
    chatGPTSearchEngineOptions,
} from '../../../../share/QnaConstant'
import { updateUserSetting } from '../../../../actions/user'
import AutocompleteExt from '../../../../components/Autocomplete'
import CheckboxExt from '../../../../components/Checkbox'
import TextareaAutosizeExt from '../../../../components/TextareaAutosize'
import Header from '../../../../components/Header'
import DndPriorityChainSettings from '../../../../components/dnd/DndPriorityChainSettings'
import ErrorMessage from '../../../../components/ErrorMessage'
import { ApiError } from '../../../../interfaces/ErrorType'
import ButtonExt from '../../../../components/ButtonExt'

const qnaTenantSettingSchema = yup.object().shape({
    questionAnswerMapping: yup.object().shape({
        translationStrategy: yup.string().required(),
        preferTranslation: yup.string().required(),
        acceptableThreshold: yup.number().required(),
        initialReply: yup.string().required(),
        answerNotFound: yup.string().required(),
        chatbotStrategy: yup.string().required(),
        chatbotNlpCloud: yup.object().shape({
            searchEngine: yup.string().required(),
            nlpKeywordExtractStrategy: yup.string().required(),
            nlpExtractSimilarKeywordEnabled: yup.boolean().required(),
            nlpExtractSimilarKeywordStrategy: yup.string().required(),
            nlpGrammarSpellingCorrectionEnabled: yup.boolean().required(),
            nlpGrammarSpellingCorrectionStrategy: yup.string().required(),
            similarityScoreStrategy: yup.string().required(),
            questionAnswerPriorityChainMappings: yup.array().min(1).required(),
        }),
        chatbotGpt: yup.object().shape({
            extractKeywordEnabled: yup.boolean().required(),
            questionAnswerPriorityChainMappings: yup.array().min(1).required(),
        }),
    }),
})

const QnATenantSettingDetail: FunctionComponent<QnATenantSettingWrapper> = ({
    wrapper,
    callback,
}) => {
    const [qnaTenantSetting, setQnaTenantSetting] = useState<QnATenantSetting>(
        wrapper
            ? wrapper
            : {
                  questionAnswerMapping: {
                      translationStrategy: translationStrategyOptions[0].value,
                      preferTranslation: preferTranslationOption[0].value,
                      acceptableThreshold: NaN,
                      initialReply: '',
                      answerNotFound: '',
                      chatbotStrategy: chatbotStrategyOptions[0].value,
                      chatbotNlpCloud: {
                          searchEngine: nlpCloudSearchEngineOptions[0].value,
                          nlpKeywordExtractStrategy:
                          nlpCloudKeywordExtractStrategyOptions[0].value,
                          nlpExtractSimilarKeywordEnabled: false,
                          nlpExtractSimilarKeywordStrategy:
                          nlpCloudExtractSimilarKeywordStrategyOptions[0].value,
                          nlpGrammarSpellingCorrectionEnabled: false,
                          nlpGrammarSpellingCorrectionStrategy:
                          nlpCloudGrammarSpellingCorrectionStrategyOptions[0].value,
                          similarityScoreStrategy:
                          nlpCloudSimilarityScoreStrategyOptions[0].value,
                          questionAnswerPriorityChainMappings: [],
                      },
                      chatbotGpt: {
                          searchEngine: chatGPTSearchEngineOptions[0].value,
                          extractKeywordEnabled: false,
                          extractWeekEnabled: false,
                          questionAnswerPriorityChainMappings: [],
                      }
                  },
              }
    )

    /**
     * Mutate content assist tenant setting update
     */
    const qnaTenantSettingUpdateMutation = useMutation<
        TenantSetting,
        ApiError,
        QnATenantSetting
    >(updateUserSetting)

    /**
     * Invoke an action to update content assist tenant setting
     * @param {*} e - event
     */
    const onSave = (values: QnATenantSetting) => {
        qnaTenantSettingUpdateMutation.mutate(values, {
            onSuccess: (data) => {
                callback(data)
                setQnaTenantSetting(data)
                values.questionAnswerMapping!!.chatbotNlpCloud.questionAnswerPriorityChainMappings = data.questionAnswerMapping!!.chatbotNlpCloud.questionAnswerPriorityChainMappings
                values.questionAnswerMapping!!.chatbotGpt.questionAnswerPriorityChainMappings = data.questionAnswerMapping!!.chatbotGpt.questionAnswerPriorityChainMappings
            },
        })
    }

    /**
     * Page containing default tenant setting details
     */
    return (
        <Box m="20px">
            <Box style={{ marginBottom: `2em` }}>
                {qnaTenantSettingUpdateMutation.isError && (
                    <ErrorMessage
                        error={qnaTenantSettingUpdateMutation.error}
                    />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={qnaTenantSetting}
                validationSchema={qnaTenantSettingSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            <AutocompleteExt
                                name="questionAnswerMapping.translationStrategy"
                                multiSelection={false}
                                label="Translation Strategy"
                                selectedValue={
                                    values.questionAnswerMapping
                                        ?.translationStrategy
                                }
                                options={translationStrategyOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    if (
                                        values.questionAnswerMapping &&
                                        qnaTenantSetting.questionAnswerMapping
                                    ) {
                                        setQnaTenantSetting({
                                            ...qnaTenantSetting,
                                            questionAnswerMapping: {
                                                ...qnaTenantSetting.questionAnswerMapping,
                                                translationStrategy: v,
                                            },
                                        })
                                        values.questionAnswerMapping = {
                                            ...values.questionAnswerMapping,
                                            translationStrategy: v,
                                        }
                                    }
                                }}
                                disableUnselectAll={true}
                                required={true}
                            />

                            <AutocompleteExt
                                name="questionAnswerMapping.preferTranslation"
                                multiSelection={false}
                                label="Prefer Translation"
                                selectedValue={
                                    values.questionAnswerMapping
                                        ?.preferTranslation
                                }
                                options={preferTranslationOption}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    if (
                                        values.questionAnswerMapping &&
                                        qnaTenantSetting.questionAnswerMapping
                                    ) {
                                        setQnaTenantSetting({
                                            ...qnaTenantSetting,
                                            questionAnswerMapping: {
                                                ...qnaTenantSetting.questionAnswerMapping,
                                                preferTranslation: v,
                                            },
                                        })
                                        values.questionAnswerMapping = {
                                            ...values.questionAnswerMapping,
                                            preferTranslation: v,
                                        }
                                    }
                                }}
                                disableUnselectAll={true}
                                required={true}
                            />

                            <TextField
                                variant="filled"
                                type="number"
                                label="Acceptable Threshold"
                                inputProps={{
                                    step: 0.01,
                                    min: 0.1,
                                    max: 1.0,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                    values.questionAnswerMapping
                                        ?.acceptableThreshold
                                }
                                name="questionAnswerMapping.acceptableThreshold"
                                required={true}
                            />

                            <span></span>

                            <TextareaAutosizeExt
                                name="questionAnswerMapping.initialReply"
                                label="Initial Reply"
                                value={
                                    values.questionAnswerMapping?.initialReply
                                }
                                onBlurEvent={handleBlur}
                                onChangeEvent={handleChange}
                                minRows={3}
                                maxRows={3}
                                required={true}
                            />

                            <TextareaAutosizeExt
                                name="questionAnswerMapping.answerNotFound"
                                label="Answer Not Found"
                                value={
                                    values.questionAnswerMapping?.answerNotFound
                                }
                                onBlurEvent={handleBlur}
                                onChangeEvent={handleChange}
                                minRows={3}
                                maxRows={3}
                                required={true}
                            />

                            <AutocompleteExt
                                name="questionAnswerMapping.chatbotStrategy"
                                multiSelection={false}
                                label="Chatbot Strategy"
                                selectedValue={
                                    values.questionAnswerMapping
                                        ?.chatbotStrategy
                                }
                                options={chatbotStrategyOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    if (
                                        values.questionAnswerMapping &&
                                        qnaTenantSetting.questionAnswerMapping
                                    ) {
                                        setQnaTenantSetting({
                                            ...qnaTenantSetting,
                                            questionAnswerMapping: {
                                                ...qnaTenantSetting.questionAnswerMapping,
                                                chatbotStrategy: v,
                                            },
                                        })
                                        values.questionAnswerMapping = {
                                            ...values.questionAnswerMapping,
                                            chatbotStrategy: v,
                                        }
                                    }
                                }}
                                disableUnselectAll={true}
                                required={true}
                            />

                            {values.questionAnswerMapping?.chatbotStrategy === 'NLP_CLOUD' && (
                                <>
                                    <AutocompleteExt
                                        name="questionAnswerMapping.chatbotNlpCloud.searchEngine"
                                        multiSelection={false}
                                        label="Search Engine"
                                        selectedValue={
                                            values.questionAnswerMapping?.chatbotNlpCloud?.searchEngine
                                        }
                                        options={nlpCloudSearchEngineOptions}
                                        onBlurEvent={handleBlur}
                                        onSelect={(v) => {
                                            if (
                                                values.questionAnswerMapping &&
                                                qnaTenantSetting.questionAnswerMapping
                                            ) {
                                                setQnaTenantSetting({
                                                    ...qnaTenantSetting,
                                                    questionAnswerMapping: {
                                                        ...qnaTenantSetting.questionAnswerMapping,
                                                        chatbotNlpCloud: {
                                                            ...qnaTenantSetting.questionAnswerMapping!!.chatbotNlpCloud,
                                                            searchEngine: v,
                                                        }
                                                    },
                                                })
                                                values.questionAnswerMapping = {
                                                    ...values.questionAnswerMapping,
                                                    chatbotNlpCloud: {
                                                        ...values.questionAnswerMapping!!.chatbotNlpCloud,
                                                        searchEngine: v,
                                                    }
                                                }
                                            }
                                        }}
                                        disableUnselectAll={true}
                                        required={true}
                                    />

                                    <AutocompleteExt
                                        name="questionAnswerMapping.chatbotNlpCloud.keywordExtractionStrategy"
                                        multiSelection={false}
                                        label="Keyword Extraction Strategy"
                                        selectedValue={
                                            values.questionAnswerMapping
                                                ?.chatbotNlpCloud
                                                ?.nlpKeywordExtractStrategy
                                        }
                                        options={nlpCloudKeywordExtractStrategyOptions}
                                        onBlurEvent={handleBlur}
                                        onSelect={(v) => {
                                            if (
                                                values.questionAnswerMapping &&
                                                qnaTenantSetting.questionAnswerMapping
                                            ) {
                                                setQnaTenantSetting({
                                                    ...qnaTenantSetting,
                                                    questionAnswerMapping: {
                                                        ...qnaTenantSetting.questionAnswerMapping,
                                                        chatbotNlpCloud: {
                                                            ...qnaTenantSetting.questionAnswerMapping!!.chatbotNlpCloud,
                                                            nlpKeywordExtractStrategy: v,
                                                        }
                                                    },
                                                })
                                                values.questionAnswerMapping = {
                                                    ...values.questionAnswerMapping,
                                                    chatbotNlpCloud: {
                                                        ...values.questionAnswerMapping!!.chatbotNlpCloud,
                                                        nlpKeywordExtractStrategy: v,
                                                    }
                                                }
                                            }
                                        }}
                                        disableUnselectAll={true}
                                        required={true}
                                    />

                                    <CheckboxExt
                                        name="questionAnswerMapping.chatbotNlpCloud.nlpExtractSimilarKeywordEnabled"
                                        label="Nlp Extract Similar Keyword Enabled"
                                        onBlurEvent={handleBlur}
                                        onChangeEvent={handleChange}
                                        value={
                                            values.questionAnswerMapping
                                                ?.chatbotNlpCloud
                                                ?.nlpExtractSimilarKeywordEnabled
                                        }
                                    />

                                    <AutocompleteExt
                                        name="questionAnswerMapping.chatbotNlpCloud.nlpExtractSimilarKeywordStrategy"
                                        multiSelection={false}
                                        label="Nlp Extract Similar Keyword Strategy"
                                        selectedValue={
                                            values.questionAnswerMapping
                                                ?.chatbotNlpCloud
                                                ?.nlpExtractSimilarKeywordStrategy
                                        }
                                        options={
                                            nlpCloudExtractSimilarKeywordStrategyOptions
                                        }
                                        onBlurEvent={handleBlur}
                                        onSelect={(v) => {
                                            if (
                                                values.questionAnswerMapping &&
                                                qnaTenantSetting.questionAnswerMapping
                                            ) {
                                                setQnaTenantSetting({
                                                    ...qnaTenantSetting,
                                                    questionAnswerMapping: {
                                                        ...qnaTenantSetting.questionAnswerMapping,
                                                        chatbotNlpCloud: {
                                                            ...qnaTenantSetting.questionAnswerMapping!!.chatbotNlpCloud,
                                                            nlpExtractSimilarKeywordStrategy: v,
                                                        }
                                                    },
                                                })
                                                values.questionAnswerMapping = {
                                                    ...values.questionAnswerMapping,
                                                    chatbotNlpCloud: {
                                                        ...values.questionAnswerMapping!!.chatbotNlpCloud,
                                                        nlpExtractSimilarKeywordStrategy: v,
                                                    }
                                                }
                                            }
                                        }}
                                        disableUnselectAll={true}
                                        required={
                                            values.questionAnswerMapping &&
                                            values.questionAnswerMapping
                                                .chatbotNlpCloud
                                                .nlpExtractSimilarKeywordEnabled
                                        }
                                        editable={
                                            values.questionAnswerMapping &&
                                            values.questionAnswerMapping
                                                .chatbotNlpCloud
                                                .nlpExtractSimilarKeywordEnabled
                                        }
                                    />

                                    <CheckboxExt
                                        name="questionAnswerMapping.chatbotNlpCloud.nlpGrammarSpellingCorrectionEnabled"
                                        label="Nlp Grammar Spelling Correction Enabled"
                                        onBlurEvent={handleBlur}
                                        onChangeEvent={handleChange}
                                        value={
                                            values.questionAnswerMapping
                                                ?.chatbotNlpCloud
                                                ?.nlpGrammarSpellingCorrectionEnabled
                                        }
                                    />

                                    <AutocompleteExt
                                        name="questionAnswerMapping.chatbotNlpCloud.nlpGrammarSpellingCorrectionStrategy"
                                        multiSelection={false}
                                        label="Nlp Grammar Spelling Correction Strategy"
                                        selectedValue={
                                            values.questionAnswerMapping
                                                ?.chatbotNlpCloud
                                                ?.nlpGrammarSpellingCorrectionStrategy
                                        }
                                        options={
                                            nlpCloudGrammarSpellingCorrectionStrategyOptions
                                        }
                                        onBlurEvent={handleBlur}
                                        onSelect={(v) => {
                                            if (
                                                values.questionAnswerMapping &&
                                                qnaTenantSetting.questionAnswerMapping
                                            ) {
                                                setQnaTenantSetting({
                                                    ...qnaTenantSetting,
                                                    questionAnswerMapping: {
                                                        ...qnaTenantSetting.questionAnswerMapping,
                                                        chatbotNlpCloud: {
                                                            ...qnaTenantSetting.questionAnswerMapping!!.chatbotNlpCloud,
                                                            nlpGrammarSpellingCorrectionStrategy: v,
                                                        }
                                                    },
                                                })
                                                values.questionAnswerMapping = {
                                                    ...values.questionAnswerMapping,
                                                    chatbotNlpCloud: {
                                                        ...values.questionAnswerMapping!!.chatbotNlpCloud,
                                                        nlpGrammarSpellingCorrectionStrategy: v,
                                                    }
                                                }
                                            }
                                        }}
                                        disableUnselectAll={true}
                                        required={
                                            values.questionAnswerMapping &&
                                            values.questionAnswerMapping
                                                .chatbotNlpCloud
                                                .nlpGrammarSpellingCorrectionEnabled
                                        }
                                        editable={
                                            values.questionAnswerMapping &&
                                            values.questionAnswerMapping
                                                .chatbotNlpCloud
                                                .nlpGrammarSpellingCorrectionEnabled
                                        }
                                    />

                                    <AutocompleteExt
                                        name="questionAnswerMapping.similarityScoreStrategy"
                                        multiSelection={false}
                                        label="Similarity Score Strategy"
                                        selectedValue={
                                            values.questionAnswerMapping
                                                ?.chatbotNlpCloud
                                                ?.similarityScoreStrategy
                                        }
                                        options={nlpCloudSimilarityScoreStrategyOptions}
                                        onBlurEvent={handleBlur}
                                        onSelect={(v) => {
                                            if (
                                                values.questionAnswerMapping &&
                                                qnaTenantSetting.questionAnswerMapping
                                            ) {
                                                setQnaTenantSetting({
                                                    ...qnaTenantSetting,
                                                    questionAnswerMapping: {
                                                        ...qnaTenantSetting.questionAnswerMapping,
                                                        chatbotNlpCloud: {
                                                            ...qnaTenantSetting.questionAnswerMapping!!.chatbotNlpCloud,
                                                            similarityScoreStrategy: v,
                                                        }
                                                    },
                                                })
                                                values.questionAnswerMapping = {
                                                    ...values.questionAnswerMapping,
                                                    chatbotNlpCloud: {
                                                        ...values.questionAnswerMapping!!.chatbotNlpCloud,
                                                        similarityScoreStrategy: v,
                                                    }
                                                }
                                            }
                                        }}
                                        disableUnselectAll={true}
                                        required={true}
                                    />
                                </>
                            )}

                            {values.questionAnswerMapping?.chatbotStrategy === 'CHATGPT' && (
                                <>
                                    <AutocompleteExt
                                        name="questionAnswerMapping.chatbotGpt.searchEngine"
                                        multiSelection={false}
                                        label="Search Engine"
                                        selectedValue={
                                            values.questionAnswerMapping?.chatbotGpt?.searchEngine
                                        }
                                        options={chatGPTSearchEngineOptions}
                                        onBlurEvent={handleBlur}
                                        onSelect={(v) => {
                                            if (
                                                values.questionAnswerMapping &&
                                                qnaTenantSetting.questionAnswerMapping
                                            ) {
                                                setQnaTenantSetting({
                                                    ...qnaTenantSetting,
                                                    questionAnswerMapping: {
                                                        ...qnaTenantSetting.questionAnswerMapping,
                                                        chatbotGpt: {
                                                            ...qnaTenantSetting.questionAnswerMapping!!.chatbotGpt,
                                                            searchEngine: v,
                                                        }
                                                    },
                                                })
                                                values.questionAnswerMapping = {
                                                    ...values.questionAnswerMapping,
                                                    chatbotGpt: {
                                                        ...values.questionAnswerMapping!!.chatbotGpt,
                                                        searchEngine: v,
                                                    }
                                                }
                                            }
                                        }}
                                        disableUnselectAll={true}
                                        required={true}
                                    />
                                    <CheckboxExt
                                        name="questionAnswerMapping.chatbotGpt.extractKeywordEnabled"
                                        label="Extract Keyword Enabled"
                                        onBlurEvent={handleBlur}
                                        onChangeEvent={handleChange}
                                        value={
                                            values.questionAnswerMapping
                                                ?.chatbotGpt
                                                ?.extractKeywordEnabled
                                        }
                                    />
                                    <CheckboxExt
                                        name="questionAnswerMapping.chatbotGpt.extractWeekEnabled"
                                        label="Extract Week Enabled"
                                        onBlurEvent={handleBlur}
                                        onChangeEvent={handleChange}
                                        value={
                                            values.questionAnswerMapping
                                                ?.chatbotGpt
                                                ?.extractWeekEnabled
                                        }
                                    />
                                </>
                            )}
                        </Box>

                        {values.questionAnswerMapping?.chatbotStrategy === 'NLP_CLOUD' && (
                            <>
                                <Box
                                    display="grid"
                                    gap="0 0 0 30px"
                                    mt="25px"
                                    gridTemplateColumns="repeat(1, minmax(0,1fr))"
                                >
                                    <Header title="Priority Chain" subtitle="Order" />

                                    <DndPriorityChainSettings
                                        data={values.questionAnswerMapping?.chatbotNlpCloud?.questionAnswerPriorityChainMappings.map(
                                            (each: any) => {
                                                return {
                                                    label: each.questionAnswerChainFilterStrategy,
                                                    value: each.questionAnswerChainFilterStrategy,
                                                    enabled: each.enabled,
                                                }
                                            }
                                        )}
                                        setData={(data) => {
                                            const atLeastOneIsEnabled = data.find(
                                                (each) => each.enabled
                                            )

                                            if (
                                                qnaTenantSetting.questionAnswerMapping &&
                                                values.questionAnswerMapping &&
                                                atLeastOneIsEnabled
                                            ) {
                                                const reprioritizedList = data.map(
                                                    (each) => {
                                                        return {
                                                            questionAnswerChainFilterStrategy:
                                                            each.value,
                                                            enabled: each.enabled,
                                                        }
                                                    }
                                                )

                                                setQnaTenantSetting({
                                                    ...qnaTenantSetting,
                                                    questionAnswerMapping: {
                                                        ...qnaTenantSetting.questionAnswerMapping,
                                                        chatbotNlpCloud: {
                                                            ...qnaTenantSetting.questionAnswerMapping.chatbotNlpCloud,
                                                            questionAnswerPriorityChainMappings: reprioritizedList,
                                                        }
                                                    },
                                                })

                                                values.questionAnswerMapping = {
                                                    ...values.questionAnswerMapping,
                                                    chatbotNlpCloud: {
                                                        ...values.questionAnswerMapping.chatbotNlpCloud,
                                                        questionAnswerPriorityChainMappings: reprioritizedList,
                                                    }
                                                }
                                            }
                                        }}
                                        editable={true}
                                    />
                                </Box>
                            </>
                        )}

                        {values.questionAnswerMapping?.chatbotStrategy === 'CHATGPT' && (
                            <>
                                <Box
                                    display="grid"
                                    gap="0 0 0 30px"
                                    mt="25px"
                                    gridTemplateColumns="repeat(1, minmax(0,1fr))"
                                >
                                    <Header title="Priority Chain" subtitle="Order" />

                                    <DndPriorityChainSettings
                                        data={values.questionAnswerMapping?.chatbotGpt?.questionAnswerPriorityChainMappings.map(
                                            (each: any) => {
                                                return {
                                                    label: each.questionAnswerChainFilterStrategy,
                                                    value: each.questionAnswerChainFilterStrategy,
                                                    enabled: each.enabled,
                                                }
                                            }
                                        )}
                                        setData={(data) => {
                                            const atLeastOneIsEnabled = data.find(
                                                (each) => each.enabled
                                            )

                                            if (
                                                qnaTenantSetting.questionAnswerMapping &&
                                                values.questionAnswerMapping &&
                                                atLeastOneIsEnabled
                                            ) {
                                                const reprioritizedList = data.map(
                                                    (each) => {
                                                        return {
                                                            questionAnswerChainFilterStrategy:
                                                            each.value,
                                                            enabled: each.enabled,
                                                        }
                                                    }
                                                )

                                                setQnaTenantSetting({
                                                    ...qnaTenantSetting,
                                                    questionAnswerMapping: {
                                                        ...qnaTenantSetting.questionAnswerMapping,
                                                        chatbotGpt: {
                                                            ...qnaTenantSetting.questionAnswerMapping.chatbotGpt,
                                                            questionAnswerPriorityChainMappings: reprioritizedList,
                                                        }
                                                    },
                                                })

                                                values.questionAnswerMapping = {
                                                    ...values.questionAnswerMapping,
                                                    chatbotGpt: {
                                                        ...values.questionAnswerMapping.chatbotGpt,
                                                        questionAnswerPriorityChainMappings: reprioritizedList,
                                                    }
                                                }
                                            }
                                        }}
                                        editable={true}
                                    />
                                </Box>
                            </>
                        )}

                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    qnaTenantSettingUpdateMutation.isLoading
                                        ? 'Saving...'
                                        : 'Save'
                                }
                                disabled={
                                    qnaTenantSettingUpdateMutation.isLoading
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}
export default QnATenantSettingDetail
