import SecureAxios from '../extensions/SecureAxios'
import store from '../redux/store'
import {
    LOAD_COUNTRIES_LIST,
    LOAD_LOCALES_LIST,
    UPDATE_COUNTRIES,
    UPDATE_LOCALES,
} from '../redux/actionType'

/**
 * An action to fetch all countries
 */
export async function fetchAllCountries() {
    let getCountryListUrl = '/consumer/country?page=1&size=100000'

    const { data } = await SecureAxios.get(getCountryListUrl)

    store.dispatch({
        type: LOAD_COUNTRIES_LIST,
        payload: data.data.content,
    })

    return data.data.content
}

/**
 * An action to fetch all locales
 */
export async function fetchAllLocales() {
    let getLocaleListUrl = '/consumer/locale?page=1&size=100000'

    const { data } = await SecureAxios.get(getLocaleListUrl)

    store.dispatch({
        type: LOAD_LOCALES_LIST,
        payload: data.data.content,
    })

    return data.data.content
}

/**
 * An action to fetch locale from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchLocales = async (searchOptions) => {
    let localeUrl = `/consumer/locale?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        localeUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }
    const { data } = await SecureAxios.get(localeUrl)
    return data
}

/**
 * An action to create the current locale
 */
export async function createLocale(locale) {
    const { data } = await SecureAxios.post(`/consumer/locale`, locale, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    store.dispatch({
        type: UPDATE_LOCALES,
        payload: { data: data.data },
    })

    return data.data
}

/**
 * An action to update the locale
 */
export async function updateLocale(locale) {
    const { data } = await SecureAxios.put(
        `/consumer/locale/${locale.id}/update`,
        locale,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    store.dispatch({
        type: UPDATE_LOCALES,
        payload: { keyField: 'id', data: data.data },
    })

    return data.data
}

/**
 * An action to fetch countries from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchCountries = async (searchOptions) => {
    let countryUrl = `/consumer/country?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        countryUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }
    const { data } = await SecureAxios.get(countryUrl)
    return data
}

/**
 * An action to create the current country
 */
export async function createCountry(country) {
    const requestBody = {
        ...country,
        localeIds: country.locales.map((locale) => locale.id),
    }
    const { data } = await SecureAxios.post(`/consumer/country`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    store.dispatch({
        type: UPDATE_COUNTRIES,
        payload: { data: data.data },
    })

    return data.data
}

/**
 * An action to update the country
 */
export async function updateCountry(country) {
    const requestBody = {
        ...country,
        localeIds: country.locales.map((locale) => locale.id),
    }
    const { data } = await SecureAxios.put(
        `/consumer/country/${country.id}/update`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    store.dispatch({
        type: UPDATE_COUNTRIES,
        payload: { keyField: 'id', data: data.data },
    })

    return data.data
}
