import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { connect } from 'react-redux'
import { Box } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
    DefaultTenantSetting,
    DefaultTenantSettingWrapper,
    TenantSetting,
} from '../../../../interfaces/SettingType'
import { Country } from '../../../../interfaces/LocalizationType'
import CheckboxExt from '../../../../components/Checkbox'
import AutocompleteExt from '../../../../components/Autocomplete'
import { updateUserSetting } from '../../../../actions/user'
import ErrorMessage from '../../../../components/ErrorMessage'
import { ApiError } from '../../../../interfaces/ErrorType'
import ButtonExt from '../../../../components/ButtonExt'

const defaultTenantSettingSchema = yup.object().shape({
    debug: yup.boolean().optional(),
    countryMapping: yup.object().shape({
        defaultLocaleId: yup.string().required(),
        countryId: yup.string().required(),
    }),
})

const DefaultTenantSettingDetail: FunctionComponent<
    DefaultTenantSettingWrapper
> = ({ countryList, wrapper, callback }) => {
    const [defaultTenantSetting, setDefaultTenantSetting] =
        useState<DefaultTenantSetting>(
            wrapper
                ? wrapper
                : {
                      debugMode: false,
                      countryMapping: {
                          defaultLocaleId: undefined,
                          countryId: undefined,
                          country: undefined,
                      },
                  }
        )

    const countryOptions = countryList?.map((locale: Country) => {
        return {
            label: locale.name,
            value: locale.id,
        }
    })

    const localeOptions = defaultTenantSetting.countryMapping?.country
        ? defaultTenantSetting.countryMapping?.country.locales.map(
              (locale: any) => {
                  return {
                      label: locale.name,
                      value: locale.id,
                  }
              }
          )
        : []

    /**
     * Mutate default tenant setting update
     */
    const defaultTenantSettingUpdateMutation = useMutation<
        TenantSetting,
        ApiError,
        DefaultTenantSetting
    >(updateUserSetting, {
        onSuccess: (data) => {
            callback(data)
        },
    })

    /**
     * Invoke an action to update default tenant setting
     * @param {*} e - event
     */
    const onSave = (values: DefaultTenantSetting) => {
        defaultTenantSettingUpdateMutation.mutate(values)
    }

    /**
     * Page containing default tenant setting details
     */
    return (
        <Box m="20px">
            <Box style={{ marginBottom: `2em` }}>
                {defaultTenantSettingUpdateMutation.isError && (
                    <ErrorMessage
                        error={defaultTenantSettingUpdateMutation.error}
                    />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={defaultTenantSetting}
                validationSchema={defaultTenantSettingSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            <CheckboxExt
                                name="debugMode"
                                label="Debug Mode"
                                onBlurEvent={handleBlur}
                                onChangeEvent={handleChange}
                                value={values.debugMode}
                            />

                            <AutocompleteExt
                                name="countryMapping.countryId"
                                multiSelection={false}
                                label="Country"
                                selectedValue={values.countryMapping?.countryId}
                                options={countryOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    if (countryList) {
                                        const selectedCountry =
                                            countryList.find(
                                                (country) => country.id === v
                                            )
                                        setDefaultTenantSetting({
                                            ...defaultTenantSetting,
                                            countryMapping: {
                                                ...defaultTenantSetting.countryMapping,
                                                countryId: v,
                                                defaultLocaleId: undefined,
                                                country: selectedCountry,
                                            },
                                        })
                                        values.countryMapping = {
                                            ...values.countryMapping,
                                            countryId: v,
                                            defaultLocaleId: undefined,
                                            country: selectedCountry,
                                        }
                                    }
                                }}
                                required={true}
                            />

                            <AutocompleteExt
                                name="countryMapping.defaultLocaleId"
                                multiSelection={false}
                                label="Default Locale"
                                selectedValue={
                                    values.countryMapping?.defaultLocaleId
                                }
                                options={localeOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    setDefaultTenantSetting({
                                        ...defaultTenantSetting,
                                        countryMapping: {
                                            ...defaultTenantSetting.countryMapping,
                                            defaultLocaleId: v,
                                        },
                                    })
                                    values.countryMapping = {
                                        ...values.countryMapping,
                                        defaultLocaleId: v,
                                    }
                                }}
                                required={true}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    defaultTenantSettingUpdateMutation.isLoading
                                        ? 'Saving...'
                                        : 'Save'
                                }
                                disabled={
                                    defaultTenantSettingUpdateMutation.isLoading
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the country list through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { countryList: state.countryList.data }
}

export default connect(mapStateToProps)(DefaultTenantSettingDetail)
